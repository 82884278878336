import React from 'react';
import { AppSettings } from './../../config/app-settings.js';
import Axios from '../../axiosInstance';
import jwt from 'jwt-decode'
import Cookies from 'universal-cookie';
import errorHandler from '../../errorHandler.js';
import { ReactNotifications } from 'react-notifications-component';
import { Store } from 'react-notifications-component';

const cookies = new Cookies();

class Authenticate extends React.Component {
	
	static contextType = AppSettings;

	constructor(props) {
		super(props);
		
		this.state = {
            title: "Autenticação",
			panelForm: "",
			formDataEmail: "",
			formDataPassword: "",
			formDataRememberMe: false,
			authenticated: false			
        };

		this.handleOnChange = this.handleOnChange.bind(this);
		
  	}
  
	componentDidMount() {
		this.setState({ form_send_status_spinner: '' })
		this.context.handleSetAppSidebarNone(true);
		this.context.handleSetAppHeaderNone(true);
		this.context.handleSetAppConfigNone(true);
		this.context.handleSetAppContentClass('p-0');
	}

	componentWillUnmount() {
		this.context.handleSetAppSidebarNone(false);
		this.context.handleSetAppHeaderNone(false);
		this.context.handleSetAppConfigNone(false);
		this.context.handleSetAppContentClass('');
	}
	
	handleOnChange(event) {

		if(typeof event !== 'undefined'){
			
			if(event.target.type == 'checkbox'){
				
				if(event.target.checked == true) {
					this.setState({ 'formDataRememberMe': true });
				} else {
					this.setState({ 'formDataRememberMe': false });
				}
				
			} else {
				this.setState({ [event.target.name]: event.target.value });
			}

		}

	}

	addNotification(notificationType, notificationTitle, notificationMessage, notificationPosition) {
        Store.addNotification({
            title: notificationTitle,
            message: notificationMessage,
            type: notificationType,
            insert: "top",
            container: notificationPosition,
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: { duration: 2000 },
            dismissable: { click: true },
        });
    }

	sendForm(e){

		this.setState({ form_send_status_spinner: 'spinner spinner-sm' })

		e.preventDefault(e)
		
		var expires = ''

		if(this.state.formDataRememberMe == true){

			var date = new Date();
			expires = date;

			expires.setDate(expires.getDate()+30);

		} 
		
		var data = {}

		data = {
			"user": {
				"login": this.state.formDataEmail,
				"password": this.state.formDataPassword
			}
		}
		
		Axios.post('/authenticate', data).then(response => {

			cookies.set('cookie_autenticado', true, { path: '/', expires: expires, secure: true });
			cookies.set('cookie_token', response.data.auth.token, { path: '/', expires: expires,secure: true });
			cookies.set('cookie_refresh_token', response.data.auth.refreshToken, { path: '/', expires: expires,secure: true });
			cookies.set('cookie_client_id', jwt(response.data.auth.token).client.id, { path: '/', expires: expires, secure: true });
			cookies.set('cookie_individual_id', jwt(response.data.auth.token).individual.id, { path: '/', expires: expires, secure: true });
			cookies.set('cookie_user_id', jwt(response.data.auth.token).user.id, { path: '/', expires: expires, secure: true });
			cookies.set('cookie_client_type', jwt(response.data.auth.token).client.type, { path: '/', expires: expires, secure: true });
			cookies.set('cookie_user_alias', jwt(response.data.auth.token).user.alias, { path: '/', expires: expires, secure: true });
			cookies.set('cookie_user_type', jwt(response.data.auth.token).user.type, { path: '/', expires: expires, secure: true });

			if(this.state.formDataRememberMe == true){
				cookies.set('autoLogin', true, { path: '/', expires: expires, secure: true });
			}

			this.setState({ form_send_status_spinner: '' })
			this.addNotification('success', 'Autenticação bem-sucedida', response.data.message, 'top-right')

			setTimeout(() => {
                this.context.handleSetAppAuthenticated(true);
            }, 2000);
			
		}).catch((error) => {
			this.setState({ form_send_status_spinner: '' })
			this.addNotification('danger', 'Erro de autenticação', errorHandler(error).message, 'top-right');
		});

    }

	render() {

		if (this.context.appAuthenticated && cookies.get('cookie_autenticado') && cookies.get('cookie_autenticado') == 'true') {
			// if(cookies.get('cookie_user_type') == 5){
			// 	window.location.href = '/modulos/list-modules-locked'
			// } else {
			// 	window.location.href = '/modulos/list-modules'
			// }
			window.location.href = '/modulos/list-modules-locked'
	 	}

		const form_send_status_spinner = this.state.form_send_status_spinner

		return (
			
			<div className="login login-v1">
				<ReactNotifications />
				<div className="login-container">
					<div className="login-header">
						<div className="brand">
							<div className="d-flex align-items-center">
								<div style={{height:80, width:325, marginLeft:'35px', backgroundImage: 'url(../assets/img/logo/logo-credihabitar-tipografia-nova.png)', backgroundSize:'cover', backgroundRepeat:'no-repeat',}}></div>
							</div>
						</div>
					</div>
					<div className="login-body">
						<div className="login-content fs-13px">
							<form>
								<div className="form-floating mb-20px">
									<input type="email" className="form-control fs-13px h-45px" id="formDataEmail" name="formDataEmail" placeholder="Login" autoComplete="off" onChange={this.handleOnChange}/>
									<label htmlFor="formDataEmail" className="d-flex align-items-center py-0">Login</label>
								</div>
								<div className="form-floating mb-20px">
									<input type="password" className="form-control fs-13px h-45px" id="formDataPassword" name="formDataPassword" placeholder="Senha" autoComplete="off" onChange={this.handleOnChange}/>
									<label htmlFor="formDataPassword" className="d-flex align-items-center py-0">Senha</label>
								</div>
								<div className="form-check mb-20px">
									<input className="form-check-input" type="checkbox" value="" id="formDataRememberMe" name='formDataRememberMe' onClick={this.handleOnChange} />
									<label className="form-check-label text-gray-200" htmlFor="formDataRememberMe">
										Manter-me logado
									</label>
								</div>
								<div className="login-buttons">
									<button onClick={this.sendForm.bind(this)}  className="btn h-45px btn-success d-block w-100 btn-lg text-black-700">Acessar</button>
								</div>
								<div className='float-end' style={{marginTop: '-30px', marginRight: '-35px'}}>
									<div className={form_send_status_spinner} style={{maxWidth:'20px',maxHeight:'20px',}}></div>
								</div>
							</form>
						</div>
					</div>
				</div>

				<style>{`
					.rnc__notification-container--top-right {
						right: 20px;
						top: 20px;
					}
				`}</style>

			</div>
		)
	}
}

export default Authenticate;