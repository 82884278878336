import React from 'react';
import Cookies from 'universal-cookie';

//autenticação
import Authenticate from './../pages/login/login.js';

//usuários
import ListElementsModules from './../pages/users/list-modules.js';
import ListElementsModulesLocked from './../pages/users/list-modules-locked.js';
import ListElementsModulesItens from './../pages/users/list-modules-itens.js';

//admin
import FormElementsProfile from '../pages/admin/form-profile.js';
import ListElementsClients from '../pages/admin/list-clients.js';
import FormElementsClients from '../pages/admin/form-clients.js';
import ListElementsStaffs from '../pages/admin/list-staffs.js';
import FormElementsStaffs from '../pages/admin/form-staffs.js';
import ListElementsModulesAdmin from '../pages/admin/list-modules.js';
import FormElementsModulesAdmin from '../pages/admin/form-modules.js';
import ListElementsModulesItensAdmin from '../pages/admin/list-itens.js';
import FormElementsModulesItensAdmin from '../pages/admin/form-itens.js';

const cookies = new Cookies();

// const defaultRoute = cookies.get('cookie_user_type') == 5
//   ? { path: '/', exact: true, component: () => <ListElementsModulesLocked /> }
//   : { path: '/', exact: true, component: () => <ListElementsModules /> };
const defaultRoute = { path: '/', exact: true, component: () => <ListElementsModulesLocked /> }

const routes = [
  defaultRoute,
  {
    path: '/login',
    title: 'Autenticação',
    component: () => <Authenticate />,
  },
  // {
  //   path: '/modulos/list-modules',
  //   title: 'Para correspondentes',
  //   component: () => <ListElementsModules />,
  // },
  {
    path: '/modulos/list-modules-locked',
    title: 'Para licenciados',
    component: () => <ListElementsModulesLocked />,
  },
  {
    path: '/modulos/list-modules-itens/:id',
    title: 'Conteúdo',
    component: () => <ListElementsModulesItens />,
  },
  {
    path: '/clients/clients',
    exact: true,
    title: 'Licenciados',
    component: () => <ListElementsClients />,
  },
  {
    path: '/clients/clients/add',
    exact: true,
    title: 'Licenciados',
    component: () => <FormElementsClients />,
  },
  {
    path: '/clients/clients/edit/:id',
    exact: true,
    title: 'Licenciados',
    component: () => <FormElementsClients />,
  },
  {
    path: '/profile',
    exact: true,
    title: 'Perfil',
    component: () => <FormElementsProfile />,
  },
  {
    path: '/staffs/staffs',
    exact: true,
    title: 'Colaboradoes',
    component: () => <ListElementsStaffs />,
  },
  {
    path: '/staffs/staffs/add',
    exact: true,
    title: 'Colaboradores',
    component: () => <FormElementsStaffs />,
  },
  {
    path: '/staffs/staffs/edit/:id',
    exact: true,
    title: 'Colaboradores',
    component: () => <FormElementsStaffs />,
  },
  {
    path: '/modules/modules',
    exact: true,
    title: 'Módulos',
    component: () => <ListElementsModulesAdmin />,
  },
  {
    path: '/modules/modules/add',
    exact: true,
    title: 'Módulos',
    component: () => <FormElementsModulesAdmin />,
  },
  {
    path: '/modules/modules/edit/:id',
    exact: true,
    title: 'Módulos',
    component: () => <FormElementsModulesAdmin />,
  },
  {
    path: '/modules/modules-itens/:id?',
    exact: true,
    title: 'Módulos',
    component: () => <ListElementsModulesItensAdmin />,
  },
  {
    path: '/modules/modulesitens/add',
    exact: true,
    title: 'Módulos',
    component: () => <FormElementsModulesItensAdmin />,
  },
  {
    path: '/modules/modulesitens/edit/:id',
    exact: true,
    title: 'Módulos',
    component: () => <FormElementsModulesItensAdmin />,
  },
];

export default routes;