import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Panel, PanelBody } from './../../components/panel/panel.jsx';
import authAxios from '../../axiosInstance';
import { ReactNotifications } from 'react-notifications-component';
import { Store } from 'react-notifications-component';
import errorHandler from '../../errorHandler.js';
import { AppSettings } from './../../config/app-settings';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

class FormElementsModulesItensAdmin extends React.Component {
    static contextType = AppSettings;

    constructor(props) {
        super(props);

        this.state = {
            title: "Licenciado",
            subtitle: "",
            button: "Cadastrar",
            formDataEmpresa: "",
            formDataName: "",
            formDataEmail: "",
            formDataAlias: "",
            formDataLogin: "",
            formDataPassword: "",
            formDataPasswordRequired: "*",
            isEdit: false,
        };

        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.addNotification = this.addNotification.bind(this);
        this.sendForm = this.sendForm.bind(this);
    }

    componentDidMount() {
        const id = cookies.get('cookie_individual_id');
        if (id) {
            this.setState({ formDataId: id, formDataPasswordRequired: '', button: 'Salvar', subtitle: 'Edição', isEdit: true });
            this.getClient(id);
        }
    }

    getClient(id) {
        authAxios.get(`/individual/${id}`)
            .then(response => {
                const { name, email } = response.data.individual;
                const { alias, login } = response.data.individual.user;
                const { name: empresa } = response.data.individual.client;
                this.setState({ formDataEmpresa: empresa, formDataName: name, formDataEmail: email, formDataAlias: alias, formDataLogin: login });
            })
            .catch(error => {
                this.addNotification('danger', 'Erro', errorHandler(error).message, 'top-right');
            });
    }

    handleOnChange(event) {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    handleKeyPress(e) {
        if (!/\d/.test(e.key)) {
            e.preventDefault();
        }
    }

    addNotification(notificationType, notificationTitle, notificationMessage, notificationPosition, notificationContent) {
		
		Store.addNotification({
		  title: notificationTitle,
		  message: notificationMessage,
		  type: notificationType,
		  insert: "top",
		  container: notificationPosition,
		  animationIn: ["animated", "fadeIn"],
		  animationOut: ["animated", "fadeOut"],
		  dismiss: { duration: 2000 },
		  dismissable: { click: true },
		  content: notificationContent
		});
		
	}

    sendForm(e) {
        e.preventDefault();
    
        const formData = new FormData();
        formData.append('empresa', this.state.formDataEmpresa);
        formData.append('name', this.state.formDataName);
        formData.append('email', this.state.formDataEmail);
        formData.append('alias', this.state.formDataAlias);
        formData.append('login', this.state.formDataLogin);
    
        if (!this.state.isEdit || (this.state.isEdit && this.state.formDataPassword)) {
            formData.append('password', this.state.formDataPassword);
        }
    
        const url = `/individualProfile/${this.state.formDataId}`;
    
        authAxios({
            method: 'put',
            url: url,
            data: formData
        })
        .then(response => {

            var expires = ''

            if (cookies.get('autoLogin') && cookies.get('autoLogin') == 'true') {
                
                var date = new Date();
                    expires = date;

                    expires.setDate(expires.getDate()+30);

            }
            
            cookies.set('cookie_user_alias', this.state.formDataAlias, { path: '/', expires: expires, secure: true });

            this.addNotification('success', 'Sucesso', response.data.message, 'top-right');
            
            setTimeout(() => {
                window.location.href = '/profile';
            }, 2000);
        })
        .catch(error => {
            this.addNotification('danger', 'Erro', errorHandler(error).message, 'top-right');
        });
    }

    render() {
        const { formDataEmpresa, formDataName, formDataEmail, formDataAlias, formDataLogin, formDataPassword, isEdit } = this.state;

        return (
            <div>
                <div className="row">
                    <div className="col-md-9 mb-3 mb-md-0">
                        <h1 className="page-header" style={{ marginTop: '40px' }}>Licenciados</h1>
                    </div>
                    <div className="col-xl-3 text-end">
                        <Link to="/clients/clients" className="btn btn-lg btn-gray">Voltar</Link>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-xl-12">
                        <Panel>
                            <ReactNotifications />
                            <PanelBody>
                                <div className="row">
                                    <div className="col-md-12" style={{fontSize: '15px', marginBottom: '25px', marginTop: '25px'}}>

                                        - O campo <b>Senha</b> apenas deve ser preenchido caso deseje alterar a senha atual.

                                    </div>
                                </div>
                                <form onSubmit={this.sendForm}>
                                    <div className="row">
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label" style={{fontWeight: 500}}>Empresa *</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="formDataEmpresa"
                                                value={formDataEmpresa}
                                                onChange={this.handleOnChange}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label" style={{fontWeight: 500}}>Nome *</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="formDataName"
                                                value={formDataName}
                                                onChange={this.handleOnChange}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label" style={{fontWeight: 500}}>Email *</label>
                                            <input
                                                className="form-control"
                                                type="email"
                                                name="formDataEmail"
                                                value={formDataEmail}
                                                onChange={this.handleOnChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label" style={{fontWeight: 500}}>Apelido *</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="formDataAlias"
                                                value={formDataAlias}
                                                onChange={this.handleOnChange}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label" style={{fontWeight: 500}}>Login *</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="formDataLogin"
                                                value={formDataLogin}
                                                onChange={this.handleOnChange}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label" style={{fontWeight: 500}}>Senha {this.state.formDataPasswordRequired}</label>
                                            <input
                                                className="form-control"
                                                type="password"
                                                name="formDataPassword"
                                                value={formDataPassword}
                                                onChange={this.handleOnChange}
                                                required={!isEdit}
                                            />
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-primary">{this.state.button}</button>
                                </form>
                            </PanelBody>
                        </Panel>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(FormElementsModulesItensAdmin);
