import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import authAxios from '../../../axiosInstance';
import errorHandler from '../../../errorHandler.js';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

class DropdownProfile extends React.Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.closeDropdown = this.closeDropdown.bind(this);
		this.state = {
			dropdownOpen: false
		};
	}

	toggle() {
		this.setState(prevState => ({
			dropdownOpen: !prevState.dropdownOpen
		}));
	}
    
	closeDropdown() {
		this.setState({
			dropdownOpen: false
		});
	}

	logout(e) {

		e.preventDefault(e)

		var data = {}

		authAxios.post('/logout', data).then(response => {
			
			cookies.set('cookie_autenticado', false, { path: '/', secure: true });
			cookies.set('cookie_token', null, { path: '/', secure: true });
			cookies.set('cookie_refresh_token', null, { path: '/', secure: true });
			cookies.set('cookie_client_id', null, { path: '/', secure: true });
			cookies.set('cookie_individual_id', null, { path: '/', secure: true });
			cookies.set('cookie_user_id', null, { path: '/', secure: true });
			cookies.set('cookie_client_type', null, { path: '/', secure: true });
			cookies.set('cookie_user_alias', null, { path: '/', secure: true });
			cookies.set('cookie_user_type', null, { path: '/', secure: true });
		
			if (cookies.get('autoLogin')) {
			  cookies.set('autoLogin', null, { path: '/', secure: true });
			}
			
			window.location.href = '/login'
			
		}).catch((error) => {
			const message = errorHandler(error);
		});

	}

	render() {
		return (
			<Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} className="navbar-item navbar-user dropdown" tag="div" style={{cursor:'pointer', fontSize:'14px'}}>
				<DropdownToggle tag="a" className="navbar-link dropdown-toggle d-flex align-items-center">
					<span>
						<span className="d-none d-md-inline">{cookies.get('cookie_user_alias')}</span>
						<b className="caret"></b>
					</span>
				</DropdownToggle>
				<DropdownMenu className="dropdown-menu dropdown-menu-end" end tag="div">
					<Link to={`/profile`} className="dropdown-item" onClick={this.closeDropdown}>Editar Perfil</Link>
					<DropdownItem onClick={this.logout.bind(this)}>Sair</DropdownItem>
				</DropdownMenu>
			</Dropdown>
		);
	}
};

export default DropdownProfile;
