import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Panel, PanelBody } from './../../components/panel/panel.jsx';
import authAxios from '../../axiosInstance';
import { ReactNotifications } from 'react-notifications-component';
import { Store } from 'react-notifications-component';
import errorHandler from '../../errorHandler.js';
import { AppSettings } from './../../config/app-settings';

class FormElementsModulesItensAdmin extends React.Component {
    static contextType = AppSettings;

    constructor(props) {
        super(props);

        this.state = {
            title: "Colaborador",
            subtitle: "",
            button: "Cadastrar",
            formDataName: "",
            formDataEmail: "",
            formDataRegistryNumber: "",
            formDataAlias: "",
            formDataLogin: "",
            formDataPassword: "",
            formDataPermission: "",
            formDataPasswordRequired: "*",
            isEdit: false,
        };

        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.addNotification = this.addNotification.bind(this);
        this.sendForm = this.sendForm.bind(this);
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        if (id) {
            this.setState({ formDataId: id, formDataPasswordRequired: '', button: 'Salvar', subtitle: 'Edição', isEdit: true });
            this.getColaborador(id);
        }
    }

    getColaborador(id) {
        authAxios.get(`/individual/${id}`)
            .then(response => {
                const { name, email, registryNumber } = response.data.individual;
                const { alias, login, type } = response.data.individual.user;
                this.setState({ formDataName: name, formDataEmail: email, formDataRegistryNumber: registryNumber, formDataAlias: alias, formDataLogin: login, formDataPermission: type });
            })
            .catch(error => {
                this.addNotification('danger', 'Erro', errorHandler(error).message, 'top-right');
            });
    }

    handleOnChange(event) {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    handleKeyPress(e) {
        if (!/\d/.test(e.key)) {
            e.preventDefault();
        }
    }

    addNotification(notificationType, notificationTitle, notificationMessage, notificationPosition, notificationContent) {
		
		Store.addNotification({
		  title: notificationTitle,
		  message: notificationMessage,
		  type: notificationType,
		  insert: "top",
		  container: notificationPosition,
		  animationIn: ["animated", "fadeIn"],
		  animationOut: ["animated", "fadeOut"],
		  dismiss: { duration: 2000 },
		  dismissable: { click: true },
		  content: notificationContent
		});
		
	}

    sendForm(e) {
        e.preventDefault();
    
        const formData = new FormData();
        formData.append('name', this.state.formDataName);
        formData.append('email', this.state.formDataEmail);
        formData.append('registryNumber', this.state.formDataRegistryNumber);
        formData.append('alias', this.state.formDataAlias);
        formData.append('login', this.state.formDataLogin);
        formData.append('type', this.state.formDataPermission);
    
        if (!this.state.isEdit || (this.state.isEdit && this.state.formDataPassword)) {
            formData.append('password', this.state.formDataPassword);
        }
    
        const url = this.state.isEdit ? `/individual/${this.state.formDataId}` : '/individual';
    
        authAxios({
            method: this.state.isEdit ? 'put' : 'post',
            url: url,
            data: formData
        })
        .then(response => {
            this.addNotification('success', 'Sucesso', response.data.message, 'top-right');
            setTimeout(() => {
                window.location.href = '/staffs/staffs';
            }, 2000);
        })
        .catch(error => {
            this.addNotification('danger', 'Erro', errorHandler(error).message, 'top-right');
        });
    }

    render() {
        const { formDataName, formDataEmail, formDataRegistryNumber, formDataAlias, formDataLogin, formDataPassword, formDataPermission, isEdit } = this.state;

        return (
            <div>
                <div className="row">
                    <div className="col-md-9 mb-3 mb-md-0">
                        <h1 className="page-header" style={{ marginTop: '40px' }}>Colaboradores</h1>
                    </div>
                    <div className="col-xl-3 text-end">
                        <Link to="/staffs/staffs" className="btn btn-lg btn-gray">Voltar</Link>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-xl-12">
                        <Panel>
                            <ReactNotifications />
                            <PanelBody>
                                <div className="row">
                                    <div className="col-md-12" style={{fontSize: '15px', marginBottom: '25px', marginTop: '25px'}}>

                                        - Caso esteja fazendo um cadastro, <b>todos</b> os campos são de preenchimento obrigatório;<br></br>
                                        - Em caso de edição, o campo <b>Senha</b> apenas deve ser preenchido caso deseje alterar a senha atual.

                                    </div>
                                </div>
                                <form onSubmit={this.sendForm}>
                                    <div className="row">
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label" style={{fontWeight: 500}}>Nome *</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="formDataName"
                                                value={formDataName}
                                                onChange={this.handleOnChange}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label" style={{fontWeight: 500}}>Email *</label>
                                            <input
                                                className="form-control"
                                                type="email"
                                                name="formDataEmail"
                                                value={formDataEmail}
                                                onChange={this.handleOnChange}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label" style={{fontWeight: 500}}>Documento *</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="formDataRegistryNumber"
                                                value={formDataRegistryNumber}
                                                onChange={this.handleOnChange}
                                                onKeyPress={this.handleKeyPress}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label" style={{fontWeight: 500}}>Apelido *</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="formDataAlias"
                                                value={formDataAlias}
                                                onChange={this.handleOnChange}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label" style={{fontWeight: 500}}>Login *</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="formDataLogin"
                                                value={formDataLogin}
                                                onChange={this.handleOnChange}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label" style={{fontWeight: 500}}>Senha {this.state.formDataPasswordRequired}</label>
                                            <input
                                                className="form-control"
                                                type="password"
                                                name="formDataPassword"
                                                value={formDataPassword}
                                                onChange={this.handleOnChange}
                                                required={!isEdit}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label" style={{fontWeight: 500}}>Permissão *</label>
                                            <select
                                                className="form-select"
                                                name="formDataPermission"
                                                value={formDataPermission}
                                                onChange={this.handleOnChange}
                                                required
                                            >
                                                <option value="">Selecione</option>
                                                <option value="2">Agente</option>
                                                <option value="1">Master</option>
                                            </select>
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-primary">{this.state.button}</button>
                                </form>
                            </PanelBody>
                        </Panel>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(FormElementsModulesItensAdmin);
