import React from 'react';
import { Route } from 'react-router-dom';
import { AppSettings } from './../../config/app-settings.js';
import SidebarNavList from './sidebar-nav-list.jsx';
import Menu from './menu.jsx';

class SidebarNav extends React.Component {
	static contextType = AppSettings;
	
	constructor(props) {
		super(props);
		this.state = {
			active: -1,
			clicked: -1
		};
		
		this.handleSidebarSearch = this.handleSidebarSearch.bind(this);
	}

	handleExpand(e, i, match) {
		e.preventDefault();

		if (this.state.clicked === -1 && match) {
			this.setState(state => ({
				active: -1,
				clicked: 1
			}));
		} else {
			this.setState(state => ({
				active: (this.state.active === i ? -1 : i),
				clicked: 1
			}));
		}
	}
	
	handleSidebarSearch(e) {
		let searchValue = e.target.value;
				searchValue = searchValue.toLowerCase();
				
		this.setState(state => {
			let newMenus = [];
			
			return {
				menus: newMenus
			};
		});
	}
  
	render() {

		return (
			<div className="menu">
				{this.context.appSidebarSearch && (
					<div className="menu-search mb-n3">
						<input type="text" className="form-control" placeholder="Sidebar menu filter..." onKeyUp={this.handleSidebarSearch} />
					</div>
				)}
				
				<Menu/>
				
			</div>
		);
	}
}

export default SidebarNav;