import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Panel, PanelBody } from './../../components/panel/panel.jsx';
import { ReactNotifications, Store } from 'react-notifications-component';
import moment from 'moment-timezone';
import 'moment/locale/pt-br';
import errorHandler from '../../errorHandler';
import authAxios from '../../axiosInstance';
import { AppSettings } from './../../config/app-settings';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

moment.locale('pt-br');

class ListElementsModulesItensAdmin extends React.Component {
    static contextType = AppSettings;

    constructor(props) {
        super(props);

        let lessonId = this.props.match.params.id;

        if(!lessonId){ lessonId = ''}

        this.state = {
            title: "Usuários",
            subtitle: "Lista",
            modules_itens: [],
            lessons: [],
            showModal: false,
            itemToDeleteId: null,
            filters: {
                name: '',
                type: '',
                module: lessonId,
            }
        };

        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.getModules = this.getModules.bind(this);
        this.getModulesItens = this.getModulesItens.bind(this);
    }

    componentDidMount() {
        this.getModules(); 
        this.getModulesItens();
    }

    toggleModal = (itemId) => {
        this.setState(prevState => ({
            showModal: !prevState.showModal,
            itemToDeleteId: itemId
        }));
    };
    
    handleDeleteItem = () => {
        const itemId = this.state.itemToDeleteId;
        
        authAxios.delete(`/lessonItens/${itemId}`).then(response => {
            
            this.addNotification('success', 'Successo', response.data.message, 'top-right');
            setTimeout(() => {
                window.location.href = '/modules/modules-itens';
            }, 2000);
            
        }).catch(error => {
            this.addNotification('danger', 'Erro', errorHandler(error).message, 'top-right');
        });

        
        this.toggleModal(null);
    };

    handleFilterChange(e) {
        this.setState({
            filters: { ...this.state.filters, [e.target.name]: e.target.value }
        }, this.getModulesItens);
    }

    getModules() {
        authAxios.get('/lesson').then((response) => {
            this.setState({ lessons: response.data.lessons });
        }).catch((error) => {
            this.addNotification('danger', 'Erro', errorHandler(error).message, 'top-right');
        });
    }

    getModulesItens() {
        //const lessonId = this.props.match.params.id;
        const { type, module, name } = this.state.filters;
        const queryParams = new URLSearchParams({ type, module, name }).toString();
        const url = module ? `/lessonItens/${module}?${queryParams}` : `/lessonItens?${queryParams}`;

        authAxios.get(url).then(response => {
            const modules_itens = response.data.lessonsItens.map(item => ({
                id: item.id,
                name: item.name,
                type: item.type,
                fileUrl: item.fileUrl,
                videoId: item.videoId,
                createdAt: item.createdAt,
                lesson: item.lesson
            }));

            this.setState({ modules_itens });
        }).catch(error => {
            this.addNotification('danger', 'Erro', errorHandler(error).message, 'top-right');
        });
    }

    addNotification(notificationType, notificationTitle, notificationMessage, notificationPosition) {
        Store.addNotification({
            title: notificationTitle,
            message: notificationMessage,
            type: notificationType,
            insert: "top",
            container: notificationPosition,
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: { duration: 2000 },
            dismissable: { click: true },
        });
    }

    render() {
        const typeToText = {
            "1": "Arquivo para download",
            "2": "Vídeo aula"
        };

		const lessonId = this.props.match.params.id;

        const typeAdmin = cookies.get('cookie_user_type');

        return (
            <div>
                <div className="row">
                    <div className="col-md-9 mb-3 mb-md-0">
                        <h1 className="page-header" style={{ marginTop: '40px' }}>Conteúdo</h1>
                    </div>
                    <div className="col-xl-3 text-end">
                        <Link to="/modules/modulesitens/add" className="btn btn-lg btn-primary">Cadastrar</Link>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-xl-12">
                        <Panel>
                            <ReactNotifications />
                            <PanelBody>
                                <div className="filter-panel mb-3">
									<h1 className="page-header" style={{ marginTop: '20px', marginLeft:'15px', fontSize:'18px' }}>Filtros</h1>
									<div className="row mb-3" style={{marginLeft:'5px'}}>
                    					<div className="col-md-2">
											<input
												type="text"
												name="name"
												placeholder="Nome"
												value={this.state.filters.name}
												onChange={this.handleFilterChange}
												style={{ padding: '8px' }}
												className='form-control' 
											/>
										</div>
                                    
                                        <div className="col-md-2">
                                            <select
                                                name="module"
                                                value={this.state.filters.module} 
                                                onChange={this.handleFilterChange}
                                                className="form-select"
                                                style={{ padding: '8px' }}
                                            >
                                                <option value="">Todos os módulos</option>
                                                {this.state.lessons.map((lesson) => (
                                                    <option key={lesson.id} value={lesson.id}>
                                                        {lesson.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
										
										<div className="col-md-2">	
											<select
												name="type"
												value={this.state.filters.type}
												onChange={this.handleFilterChange}
												style={{ padding: '8px' }}
												className='form-select'
											>
												<option value="">Todos os tipos</option>
												<option value="1">Arquivo para download</option>
												<option value="2">Vídeo aula</option>
											</select>
										</div>
                                    </div>
                                </div>

                                <div className="card border-0">
                                    <div className="tab-content p-3">
                                        <div className="tab-pane fade show active" id="allTab">

                                            {this.state.modules_itens.length === 0 ? (
                                                <div className="alert alert-warning" role="alert">
                                                    Não há registros disponíveis.
                                                </div>
                                            ) : (
                                                <div className="table-responsive mb-3">
                                                    <table className="table table-hover table-panel text-nocapitalizewrap align-middle mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th>Nome</th>
                                                                <th>Módulo</th>
                                                                <th>Tipo</th>
                                                                <th>Conteúdo</th>
                                                                <th>Cadastrado em</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.modules_itens.map((modules_item, index) => (
                                                                <tr key={modules_item.id} className="border">
                                                                    <td className="border">{modules_item.name}</td>
                                                                    <td className="border">{modules_item.lesson.name}</td>
                                                                    <td className="border">{typeToText[modules_item.type] || "Tipo desconhecido"}</td>
                                                                    <td className="border">
                                                                        {modules_item.type === "1" ? modules_item.fileUrl : modules_item.videoId}
                                                                    </td>
                                                                    <td>
                                                                        {moment(modules_item.createdAt).tz("America/Sao_Paulo").format('ddd D MMM, h:mmA')}
                                                                    </td>
                                                                    <td className="text-end">
                                                                        <Link to={`/modules/modulesitens/edit/${modules_item.id}`} className="btn btn-info btn-sm">Editar</Link>
                                                                        {typeAdmin == 1 && (
                                                                            <button className="btn btn-danger btn-sm ms-1" onClick={() => this.toggleModal(modules_item.id)}>Excluir</button>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                </div>
                            </PanelBody>
                        </Panel>

                    </div>
                </div>

                <Modal isOpen={this.state.showModal} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>Confirmar exclusão</ModalHeader>
                    <ModalBody>
                        Tem certeza de que deseja excluir este conteúdo?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggleModal}>Cancelar</Button>{' '}
                        <Button color="danger" onClick={this.handleDeleteItem}>Excluir</Button>
                    </ModalFooter>
                </Modal>

            </div>

        );
    }
}

export default withRouter(ListElementsModulesItensAdmin);
