import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Panel, PanelBody } from './../../components/panel/panel.jsx';
import authAxios from '../../axiosInstance';
import { ReactNotifications } from 'react-notifications-component';
import { Store } from 'react-notifications-component';
import errorHandler from '../../errorHandler.js';
import { AppSettings } from './../../config/app-settings';

class FormElementsModulesAdmin extends React.Component {
    static contextType = AppSettings;

    constructor(props) {
        super(props);

        this.state = {
            title: "Módulos",
            subtitle: "",
            button: "Cadastrar",
            formDataId: "",
            formDataName: "",
            formDataType: "",
            formDataFileRequired: "*",
            file: null,
            isEdit: false,
        };

        this.handleOnChange = this.handleOnChange.bind(this);
        this.addNotification = this.addNotification.bind(this);
        this.sendForm = this.sendForm.bind(this);
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        if (id) {
            this.setState({ formDataId: id, formDataFileRequired: '', button: 'Salvar', subtitle: 'Edição', isEdit: true });
            this.getModule(id);
        }
    }

    getModule(id) {
        authAxios.get(`/lessonById/${id}`)
            .then(response => {
                const { name, type } = response.data.lesson;
                this.setState({ formDataName: name, formDataType: type });
            })
            .catch(error => {
                this.addNotification('danger', 'Erro', errorHandler(error).message, 'top-right');
            });
    }

    handleOnChange(event) {
        const { name, value, files } = event.target;
        if (name === "file") {
            this.setState({ [name]: files ? files[0] : null });
        } else {
            this.setState({ [name]: value });
        }
    }

	addNotification(notificationType, notificationTitle, notificationMessage, notificationPosition, notificationContent) {
		
		Store.addNotification({
		  title: notificationTitle,
		  message: notificationMessage,
		  type: notificationType,
		  insert: "top",
		  container: notificationPosition,
		  animationIn: ["animated", "fadeIn"],
		  animationOut: ["animated", "fadeOut"],
		  dismiss: { duration: 2000 },
		  dismissable: { click: true },
		  content: notificationContent
		});
		
	}

    sendForm(e) {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', this.state.formDataName);
        formData.append('type', this.state.formDataType);
        if (this.state.file) {
            formData.append('file', this.state.file);
        }
        
        const config = { headers: { 'content-type': 'multipart/form-data' } };
        const url = this.state.isEdit ? `/lesson/${this.state.formDataId}` : '/lesson';

        authAxios({
            method: this.state.isEdit ? 'put' : 'post',
            url: url,
            data: formData,
            ...config
        })
        .then(response => {
            this.addNotification('success', 'Successo', response.data.message, 'top-right');
            setTimeout(() => {
                window.location.href = '/modules/modules';
            }, 2000);
        })
        .catch(error => {
            this.addNotification('danger', 'Erro', errorHandler(error).message, 'top-right');
        });
    }

    render() {
        const { formDataName, formDataType, isEdit } = this.state;

        return (
            <div>
                <div className="row">
                    <div className="col-md-9 mb-3 mb-md-0">
                        <h1 className="page-header" style={{ marginTop: '40px' }}>Módulos</h1>
                    </div>
                    <div className="col-xl-3 text-end">
                        <Link to="/modules/modules" className="btn btn-lg btn-gray">Voltar</Link>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-xl-12">
                        <Panel>
                            <ReactNotifications />
                            <PanelBody>
                                <div className="row">
                                    <div className="col-md-12" style={{fontSize: '15px', marginBottom: '25px', marginTop: '25px'}}>

                                        - Caso esteja fazendo um cadastro, <b>todos</b> os campos são de preenchimento obrigatório;<br></br>
                                        - Em caso de edição, o campo <b>Thumb</b> apenas deve ser preenchido caso deseje alterar a thumb atual.

                                    </div>
                                </div>    
                                <form onSubmit={this.sendForm}>
                                    <div className="row">
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label" style={{fontWeight: 500}}>Nome *</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="formDataName"
                                                value={formDataName}
                                                onChange={this.handleOnChange}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label" style={{fontWeight: 500}}>Menu *</label>
                                            <select
                                                className="form-select"
                                                name="formDataType"
                                                value={formDataType}
                                                onChange={this.handleOnChange}
                                                required
                                            >
                                                <option value="">Selecione</option>
                                                <option value="1">Para correspondentes</option>
                                                <option value="2">Para licenciados</option>
                                            </select>
                                        </div>
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label" style={{fontWeight: 500}}>Thumb (.jpg,.png) - Máx. 1MB {this.state.formDataFileRequired}</label>
                                            <input
                                                type="file"
                                                className="form-control"
                                                name="file"
                                                onChange={this.handleOnChange}
                                                accept=".jpg, .png"
                                                required={!isEdit}
                                            />
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-primary">{this.state.button}</button>
                                </form>
                            </PanelBody>
                        </Panel>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(FormElementsModulesAdmin);
