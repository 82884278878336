import axios from 'axios';
import Cookies from 'universal-cookie';
import errorHandler from './errorHandler.js';
import jwt from 'jwt-decode'

const allowedOrigins = [process.env.REACT_APP_FRONTEND_REACT_HOST]
const apiUrl = process.env.REACT_APP_BACKEND_NODEJS_HOST;

const cookies = new Cookies();

const authAxios = axios.create({
    baseURL: apiUrl,
    withCredentials: true
})

const Axios = axios.create({
  baseURL: apiUrl,
  withCredentials: true
})

authAxios.defaults.headers.common['Accept'] = 'application/json'
authAxios.defaults.headers.common['Access-Control-Allow-Origin'] = allowedOrigins
authAxios.defaults.headers.common['Authorization'] = `Bearer ${cookies.get('cookie_token')}`

Axios.defaults.headers.common['Accept'] = 'application/json'
Axios.defaults.headers.common['Access-Control-Allow-Origin'] = allowedOrigins

async function RefreshAccessToken(){
  
  var expires = ''

  if (cookies.get('autoLogin') && cookies.get('autoLogin') == 'true') {
    
    var date = new Date();
		expires = date;

		expires.setDate(expires.getDate()+30);

  }
		
	var data = {}
 
  data = {
    "refreshToken": {
      "token": cookies.get('cookie_refresh_token'), 
    }
  }

  authAxios.defaults.headers.common['Authorization'] = 'Bearer ' + cookies.get('cookie_refresh_token');

  const reAuthenticate = await authAxios.post('/refresh_token', data).then(response => { 

    cookies.set('cookie_autenticado', true, { path: '/', expires: expires, secure: true });
    cookies.set('cookie_token', response.data.auth.token, { path: '/', expires: expires,secure: true });
    cookies.set('cookie_refresh_token', response.data.auth.refreshToken, { path: '/', expires: expires,secure: true });
    cookies.set('cookie_client_id', jwt(response.data.auth.token).client.id, { path: '/', expires: expires, secure: true });
    cookies.set('cookie_individual_id', jwt(response.data.auth.token).individual.id, { path: '/', expires: expires, secure: true });
    cookies.set('cookie_user_id', jwt(response.data.auth.token).user.id, { path: '/', expires: expires, secure: true });
    cookies.set('cookie_client_type', jwt(response.data.auth.token).client.type, { path: '/', expires: expires, secure: true });
    cookies.set('cookie_user_alias', jwt(response.data.auth.token).user.alias, { path: '/', expires: expires, secure: true });
    cookies.set('cookie_user_type', jwt(response.data.auth.token).user.type, { path: '/', expires: expires, secure: true });

    return {
      token: response.data.auth.token
    }

  }).catch((error) => {
    const message = errorHandler(error);
  });

  return {
    token: reAuthenticate.token
  }

}

authAxios.interceptors.request.use(
  async config => {
    return config;
  },
  error => {
    Promise.reject(error)
});

authAxios.interceptors.response.use((response) => {
  
  return response

}, async function (error) {
  
  const originalRequest = error.config; 
  
  if (error.response.status === 403 && error.response.data.message == '403 Forbidden - Token is expired' && !originalRequest._retry) {

    originalRequest._retry = true;
    
    const refreshToken = await RefreshAccessToken(); 
    
    authAxios.defaults.headers.common['Authorization'] = 'Bearer ' + refreshToken.token;
    originalRequest.headers['Authorization'] = 'Bearer ' + refreshToken.token
    
    return authAxios(originalRequest); 
  
  } else if (error.response.status === 403 && (error.response.data.message == '403 Forbidden - Refresh Token is expired' || error.response.data.message == '403 Forbidden - Token is not valid')) {

    cookies.set('cookie_autenticado', false, { path: '/', secure: true });
    cookies.set('cookie_token', null, { path: '/', secure: true });
    cookies.set('cookie_refresh_token', null, { path: '/', secure: true });
    cookies.set('cookie_client_id', null, { path: '/', secure: true });
    cookies.set('cookie_individual_id', null, { path: '/', secure: true });
    cookies.set('cookie_user_id', null, { path: '/', secure: true });
    cookies.set('cookie_client_type', null, { path: '/', secure: true });
    cookies.set('cookie_user_alias', null, { path: '/', secure: true });
    cookies.set('cookie_user_type', null, { path: '/', secure: true });
    
    if (cookies.get('autoLogin')) {
      cookies.set('autoLogin', null, { path: '/', secure: true });
    }
    
    window.location.href = '/login'

  }
  
  console.log(error.response.data.message)
  return Promise.reject(error);

});

export default authAxios;