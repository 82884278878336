import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Panel, PanelBody } from './../../components/panel/panel.jsx';
import { ReactNotifications } from 'react-notifications-component';
import { Store } from 'react-notifications-component';
import moment from 'moment-timezone';
import 'moment/locale/pt-br';
import errorHandler from '../../errorHandler.js';
import authAxios from '../../axiosInstance';
import { AppSettings } from './../../config/app-settings.js';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

moment.locale('pt-br');

class ListElementsUsers extends React.Component {
    static contextType = AppSettings;

    constructor(props) {
        super(props);
        this.state = {
            title: "Usuários",
            subtitle: "Lista",
            users: [],
            showModal: false,
            itemToDeleteId: null,
            showDisableModal: false,
            itemToDisableId: null,
            showEnableModal: false,
            itemToEnableId: null,
            filters: {
                name: '',
                alias: '',
                type: '',
                status: '1'
            }
        };

        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.getUsers = this.getUsers.bind(this);
    }

    componentDidMount() {
        this.getUsers();
    }

    toggleModal = (itemId) => {
        this.setState(prevState => ({
            showModal: !prevState.showModal,
            itemToDeleteId: itemId
        }));
    };
    
    toggleDisableModal = (itemId) => {
        this.setState(prevState => ({
            showDisableModal: !prevState.showDisableModal,
            itemToDisableId: itemId
        }));
    };

    toggleEnableModal = (itemId) => {
        this.setState(prevState => ({
            showEnableModal: !prevState.showEnableModal,
            itemToEnableId: itemId
        }));
    };

    handleDeleteItem = () => {
        const itemId = this.state.itemToDeleteId;
        
        authAxios.delete(`/individual/${itemId}`).then(response => {
            
            this.addNotification('success', 'Successo', response.data.message, 'top-right');
            setTimeout(() => {
                window.location.href = '/staffs/staffs';
            }, 2000);
            
        }).catch(error => {
            this.addNotification('danger', 'Erro', errorHandler(error).message, 'top-right');
        });
        
        this.toggleModal(null);
    };

    handleDisableItem = () => {
        const itemId = this.state.itemToDisableId;
    
        authAxios.put(`/userDisable/${itemId}`).then(response => {
            
            this.addNotification('success', 'Successo', response.data.message, 'top-right');
            setTimeout(() => {
                window.location.href = '/staffs/staffs';
            }, 2000);
            
        }).catch(error => {
            this.addNotification('danger', 'Erro', errorHandler(error).message, 'top-right');
        });
    
        this.toggleDisableModal(null);
    };

    handleEnableItem = () => {
        const itemId = this.state.itemToEnableId;
    
        authAxios.put(`/userEnable/${itemId}`).then(response => {
            
            this.addNotification('success', 'Successo', response.data.message, 'top-right');
            setTimeout(() => {
                window.location.href = '/staffs/staffs';
            }, 2000);
            
        }).catch(error => {
            this.addNotification('danger', 'Erro', errorHandler(error).message, 'top-right');
        });
    
        this.toggleEnableModal(null);
    };

    handleFilterChange(e) {
        this.setState({
            filters: { ...this.state.filters, [e.target.name]: e.target.value }
        }, this.getUsers);
    }

    getUsers() {
        const { name, alias, type, status } = this.state.filters;
        const queryParams = new URLSearchParams({ name, alias, type, status }).toString();
        const url = `/user?${queryParams}`;

        authAxios.get(url).then(response => {
            const users = response.data.users.map(user => ({
                id: user.id,
                alias: user.alias,
                type: user.type,
                status: user.status,
                individual: user.individual,
                createdAt: user.createdAt
            }));

            this.setState({ users });
        }).catch(error => {
            this.addNotification('danger', 'Erro', errorHandler(error).message, 'top-right');
        });
    }

    addNotification(notificationType, notificationTitle, notificationMessage, notificationPosition) {
        Store.addNotification({
            title: notificationTitle,
            message: notificationMessage,
            type: notificationType,
            insert: "top",
            container: notificationPosition,
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: { duration: 2000 },
            dismissable: { click: true },
        });
    }

    render() {
        const typeToText = {
            "1": "Master",
            "2": "Agente"
        };

        const typeAdmin = cookies.get('cookie_user_type');

        return (
            <div>
                <div className="row">
                    <div className="col-md-9 mb-3 mb-md-0">
                        <h1 className="page-header" style={{ marginTop: '40px' }}>Colaboradores</h1>
                    </div>
                    <div className="col-xl-3 text-end">
                        <Link to="/staffs/staffs/add" className="btn btn-lg btn-primary">Cadastrar</Link>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-xl-12">
                        <Panel>
                            <ReactNotifications />
                            <PanelBody>

								<div className="filter-panel mb-3">
									<h1 className="page-header" style={{ marginTop: '20px', marginLeft: '15px', fontSize: '18px' }}>Filtros</h1>
									<div className="row mb-3" style={{ marginLeft: '5px' }}>
										<div className="col-md-2">
											<input
												type="text"
												name="name"
												placeholder="Nome do Colaborador"
												value={this.state.filters.name}
												onChange={this.handleFilterChange}
												style={{ padding: '8px' }}
												className='form-control'
											/>
										</div>
										<div className="col-md-2">
											<input
												type="text"
												name="alias"
												placeholder="Alias"
												value={this.state.filters.alias}
												onChange={this.handleFilterChange}
												style={{ padding: '8px' }}
												className='form-control'
											/>
										</div>
										<div className="col-md-2">
											<select
												name="type"
												value={this.state.filters.type}
												onChange={this.handleFilterChange}
												style={{ padding: '8px' }}
												className='form-select'
											>
												<option value="">Todos as permissões</option>
												<option value="1">Master</option>
												<option value="2">Agente</option>
											</select>
										</div>
                                        <div className="col-md-2">
											<select
												name="status"
												value={this.state.filters.status}
												onChange={this.handleFilterChange}
												style={{ padding: '8px' }}
												className='form-select'
											>
												<option value="">Todos os status</option>
												<option value="0">Inativos</option>
												<option value="1">Ativos</option>
											</select>
										</div>
									</div>
								</div>
								
                                <div className="card border-0">
                                    <div className="tab-content p-3">
                                        <div className="tab-pane fade show active" id="allTab">

                                            {this.state.users.length === 0 ? (
                                                <div className="alert alert-warning" role="alert">
                                                    Não há registros disponíveis.
                                                </div>
                                            ) : (
                                                <div className="table-responsive mb-3">
                                                    <table className="table table-hover table-panel text-nocapitalizewrap align-middle mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th>Colaborador</th>
                                                                <th>Alias</th>
                                                                <th>Permissão</th>
                                                                <th>Cadastrado em</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.users.map((user, index) => (
                                                                <tr key={user.id} className="border">
                                                                    <td className="border">{user.individual.name}</td>
                                                                    <td className="border">{user.alias}</td>
                                                                    <td className="border">{typeToText[user.type] || "Tipo desconhecido"}</td>
                                                                    <td>{moment(user.createdAt).tz("America/Sao_Paulo").format('ddd D MMM, h:mmA')}</td>
                                                                    <td className="text-end">
                                                                        <Link to={`/staffs/staffs/edit/${user.individual.id}`} className="btn btn-info btn-sm">Editar</Link>
                                                                        
                                                                        {user.status === '1' && (
                                                                            <button className="btn btn-warning btn-sm ms-1" onClick={() => this.toggleDisableModal(user.id)}>Desativar</button>
                                                                        )}

                                                                        {user.status === '0' && (
                                                                            <button className="btn btn-warning btn-sm ms-1" onClick={() => this.toggleEnableModal(user.id)}>Ativar</button>
                                                                        )}

                                                                        {typeAdmin == 1 && (
                                                                            <button className="btn btn-danger btn-sm ms-1" onClick={() => this.toggleModal(user.individual.id)}>Excluir</button>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )}   
                                        </div>
                                    </div>
                                </div>
                            </PanelBody>
                        </Panel>

                    </div>
                </div>

                <Modal isOpen={this.state.showModal} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>Confirmar exclusão</ModalHeader>
                    <ModalBody>
                        Tem certeza de que deseja excluir este colaborador?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggleModal}>Cancelar</Button>{' '}
                        <Button color="danger" onClick={this.handleDeleteItem}>Excluir</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.showDisableModal} toggle={this.toggleDisableModal}>
                    <ModalHeader toggle={this.toggleDisableModal}>Confirmar desativação</ModalHeader>
                    <ModalBody>
                        Tem certeza de que deseja desativar este colaborador?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggleDisableModal}>Cancelar</Button>{' '}
                        <Button color="warning" onClick={this.handleDisableItem}>Desativar</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.showEnableModal} toggle={this.toggleEnableModal}>
                    <ModalHeader toggle={this.toggleEnableModal}>Confirmar habilitação</ModalHeader>
                    <ModalBody>
                        Tem certeza de que deseja habilitar este colaborador?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggleEnableModal}>Cancelar</Button>{' '}
                        <Button color="warning" onClick={this.handleEnableItem}>Ativar</Button>
                    </ModalFooter>
                </Modal>

            </div>
        );
    }
}

export default withRouter(ListElementsUsers);
