import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import authAxios from '../../axiosInstance';
import { ReactNotifications } from 'react-notifications-component';
import { Store } from 'react-notifications-component';
import errorHandler from '../../errorHandler.js';
import { AppSettings } from './../../config/app-settings.js';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

import Cookies from 'universal-cookie';

const cookies = new Cookies();
  
class ListElementsModulesLocked extends React.Component {
	
	static contextType = AppSettings;
	
	constructor(props) {

		super(props);
		
		this.state = {
            modules_locked: [],
			showUploadModal: false,
            file: null,
			completionRatio: 0
        };
	
		this.handleOnChange = this.handleOnChange.bind(this);

	}
	
	componentDidMount() {

		this.getModulesLocked();
	
	}
	
	getModulesLocked() {

		authAxios.get('/lesson/'+cookies.get('cookie_user_id')).then((response) => {

			var json = response.data
			
			const modules_locked = json.lessons
			.filter(lesson => lesson.type === "2")
			.map(lesson => ({
				id: lesson.id,
				itens: lesson.itens,
				itensCompleted: lesson.itensCompleted,
				imgUrl: "../assets/img/pos/"+lesson.thumb
			}));

			this.setState({ modules_locked })

			const totalItens = modules_locked.reduce((acc, module) => acc + module.itens, 0);
        	const totalItensCompleted = modules_locked.reduce((acc, module) => acc + module.itensCompleted, 0);
        	const completionRatio = totalItens > 0 ? (totalItensCompleted / totalItens) * 100 : 0;
        	this.setState({ completionRatio: completionRatio.toFixed(2) });

		}).catch((error) => {
			this.addNotification('danger', 'Erro', errorHandler(error).message, 'top-right');
		});

	}

	toggleUploadModal = () => {
        this.setState(prevState => ({
            showUploadModal: !prevState.showUploadModal
        }));
    }

    handleOnChange(event) {
        const { name, value, files } = event.target;
        if (name === "file") {
            this.setState({ [name]: files ? files[0] : null });
        }
    }

    handleUpload = (e) => {
        
		e.preventDefault();

		const formData = new FormData();
        formData.append('file', this.state.file);
        
        const config = { headers: { 'content-type': 'multipart/form-data' } };
        const url = `/uploadBannerLicenciado/2`;

        authAxios({
            method: 'put',
            url: url,
            data: formData,
            ...config
        })
        .then(response => {
            this.addNotification('success', 'Sucesso', response.data.message, 'top-right');
            setTimeout(() => {
                window.location.href = '/modulos/list-modules-locked';
            }, 2000);
        })
        .catch(error => {
            this.addNotification('danger', 'Erro', errorHandler(error).message, 'top-right');
        });
        
        this.setState({
            showUploadModal: false,
            file: null
        });
    }

	addNotification(notificationType, notificationTitle, notificationMessage, notificationPosition, notificationContent) {
		
		Store.addNotification({
		  title: notificationTitle,
		  message: notificationMessage,
		  type: notificationType,
		  insert: "top",
		  container: notificationPosition,
		  animationIn: ["animated", "fadeIn"],
		  animationOut: ["animated", "fadeOut"],
		  dismiss: { duration: 2000 },
		  dismissable: { click: true },
		  content: notificationContent
		});
		
	}

	render() {

		const typeAdmin = cookies.get('cookie_user_type');
		
		const clientType = cookies.get('cookie_client_type');

		let uploadBanner = '';
		let progressBar = '';

		// if(clientType == 1){
			
		// 	uploadBanner = <div className="upload-icon" onClick={this.toggleUploadModal} style={{position:'absolute', right:'25px', cursor:'pointer'}}>
		// 	<i className="fas fa-lg fa-fw me-10px fa-image fs-16px" style={{marginTop:'15px'}}></i>
		// 	</div>
		// } else {
		// 	progressBar = <div className="progress mb-2">
		// 		<div className="progress-bar fs-14px fw-bold" style={{width: `${this.state.completionRatio}%`}}>{this.state.completionRatio}%</div>
		// 	</div>
		// }

		return (

			<div className="vh-100">
				<div className="pos pos-stock" id="pos-stock">

					{uploadBanner}
					<ReactNotifications />	
					<div className="d-flex align-items-center">
						<div style={{height:350, minWidth:'100%', backgroundImage: 'url(../assets/img/pos/banner_2.jpg)', backgroundSize:'cover', backgroundRepeat:'no-repeat'}}></div>
					</div>
					
					<div className="row">
						<div className="col-md-6 mb-3 mb-md-0">
							<h1 className="page-header" style={{marginTop:'40px'}}>Para licenciados</h1>
						</div>
						<div className="col-md-6" style={{marginTop:'50px'}}>
							{progressBar}
						</div>	
					</div>
					
					<div className="pos-stock-body">
						<div className="pos-stock-content">
							<div className="pos-stock-content-container">
								<div className="row gx-0">
									{this.state.modules_locked.map((module_locked) => (
										<div key={module_locked.id} className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
											<Link
												to={typeAdmin == 1 || typeAdmin == 2 || typeAdmin == 4 || typeAdmin == 5
													? `/modulos/list-modules-itens/${module_locked.id}`
													: "#"
												}
												style={{
													textDecoration: 'none',
													color: 'inherit',
													cursor: typeAdmin == 1 || typeAdmin == 2 || typeAdmin == 4 || typeAdmin == 5 ? 'pointer' : 'not-allowed',
												}}
												>
												<div className={((typeAdmin == 1 || typeAdmin == 2 || typeAdmin == 4 || typeAdmin == 5) ? "pos-stock-product" : "pos-stock-product-opacity")} style={{ position: 'relative' }}>
													{/* {module_locked.itens === module_locked.itensCompleted && cookies.get('cookie_client_type') == 3 && (
														<div className="ribbon">Concluído!</div>
													)} */}
													<div className="pos-stock-product-container">
														<div className="product">
															<div className="product-img">
																<div className="img" style={{ backgroundImage: `url(${module_locked.imgUrl})` }}></div>
															</div>
															<div className="product-action">
																
																{typeAdmin == 1 || typeAdmin == 2 || typeAdmin == 4 || typeAdmin == 5 ? (
																	<Link to={'/modulos/list-modules-itens/' + module_locked.id} className="btn btn-primary">
																		Acessar <i className="fas fa-lg fa-fw me-10px fa-lock-open fs-16px" style={{marginLeft:'6px'}}></i>
																	</Link>
																) : (
																	<button disabled={true} className="btn btn-primary">
																		Acessar <i className="fas fa-lg fa-fw me-10px fa-lock fs-16px" style={{marginLeft:'6px'}}></i>
																	</button>
																)}

															</div>
														</div>
													</div>
												</div>
											</Link>	
										</div>
									))}
								</div>
								<div className="row">
									<div className="col-md-12">
										<br></br><br></br><br></br>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<Modal isOpen={this.state.showUploadModal} toggle={this.toggleUploadModal}>
					<ModalHeader toggle={this.toggleUploadModal}>Selecionar arquivo</ModalHeader>
					<ModalBody>
						<form>
							<div className="row">
								<div className="mb-3 col-md-12">
									<label className="form-label" style={{fontWeight: 500}}>Banner (.jpg) - Máx. 1MB</label>
									<input
										type="file"
										className="form-control"
										name="file"
										onChange={this.handleOnChange}
										accept=".jpg"
										required='true'
									/>
								</div>
							</div>
						</form>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={this.handleUpload}>Enviar</Button>
						<Button color="secondary" onClick={this.toggleUploadModal}>Cancelar</Button>
					</ModalFooter>
				</Modal>

			</div>
			
		)
	}
}

export default withRouter(ListElementsModulesLocked);