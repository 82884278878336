import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Panel, PanelBody } from './../../components/panel/panel.jsx';
import { ReactNotifications } from 'react-notifications-component';
import { Store } from 'react-notifications-component';
import moment from 'moment-timezone';
import 'moment/locale/pt-br';
import errorHandler from '../../errorHandler.js';
import authAxios from '../../axiosInstance';
import { AppSettings } from './../../config/app-settings';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

moment.locale('pt-br');

class ListElementsModulesAdmin extends React.Component {
    static contextType = AppSettings;

    constructor(props) {
        super(props);

        this.state = {
            title: "Usuários",
            subtitle: "Lista",
            modules: [],
            showModal: false,
            itemToDeleteId: null,
            filters: {
                module: '',
                type: '',
            }
        };

        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.getModules = this.getModules.bind(this);
    }

    componentDidMount() {
        this.getModules();
    }

    toggleModal = (itemId) => {
        this.setState(prevState => ({
            showModal: !prevState.showModal,
            itemToDeleteId: itemId
        }));
    };
    
    handleDeleteItem = () => {
        const itemId = this.state.itemToDeleteId;
        
        authAxios.delete(`/lesson/${itemId}`).then(response => {
            
            this.addNotification('success', 'Successo', response.data.message, 'top-right');
            setTimeout(() => {
                window.location.href = '/modules/modules';
            }, 2000);
            
        }).catch(error => {
            this.addNotification('danger', 'Erro', errorHandler(error).message, 'top-right');
        });

        
        this.toggleModal(null);
    };

    handleFilterChange(e) {
        this.setState({
            filters: { ...this.state.filters, [e.target.name]: e.target.value }
        }, this.getModules);
    }

    getModules() {
        const { module, type } = this.state.filters;
        const queryParams = new URLSearchParams({ name: module, type }).toString();
        const url = `/lesson?${queryParams}`;

        authAxios.get(url).then(response => {
            const modules = response.data.lessons.map(module => ({
                id: module.id,
                name: module.name,
                type: module.type,
                createdAt: module.createdAt
            }));

            this.setState({ modules });
        }).catch(error => {
            this.addNotification('danger', 'Erro', errorHandler(error).message, 'top-right');
        });
    }

    addNotification(notificationType, notificationTitle, notificationMessage, notificationPosition) {
        Store.addNotification({
            title: notificationTitle,
            message: notificationMessage,
            type: notificationType,
            insert: "top",
            container: notificationPosition,
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: { duration: 2000 },
            dismissable: { click: true },
        });
    }

    render() {

        const typeToText = {
            "1": "Para correspondentes",
            "2": "Para licenciados"
        };

        const typeAdmin = cookies.get('cookie_user_type');

        return (
            <div>
                <div className="row">
                    <div className="col-md-9 mb-3 mb-md-0">
                        <h1 className="page-header" style={{ marginTop: '40px' }}>Módulos</h1>
                    </div>
                    <div className="col-xl-3 text-end">
                        <Link to="/modules/modules/add" className="btn btn-lg btn-primary">Cadastrar</Link>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-xl-12">
                        <Panel>
                            <ReactNotifications />
                            <PanelBody>
								<div className="filter-panel mb-3">
									<h1 className="page-header" style={{ marginTop: '20px', marginLeft:'15px', fontSize:'18px' }}>Filtros</h1>
									<div className="row mb-3" style={{marginLeft:'5px'}}>
										<div className="col-md-2">
											<input
												type="text"
												name="module"
												placeholder="Nome do Módulo"
												value={this.state.filters.module}
												onChange={this.handleFilterChange}
												style={{ padding: '8px' }}
												className='form-control' 
											/>
										</div>
										<div className="col-md-2">    
											<select
												name="type"
												value={this.state.filters.type}
												onChange={this.handleFilterChange}
												style={{ padding: '8px' }}
												className='form-select'
											>
												<option value="">Todos as permissões</option>
												<option value="1">Para correspondentes</option>
												<option value="2">Para licenciados</option>
											</select>
										</div>
									</div>
								</div>

                                <div className="card border-0">
                                    <div className="tab-content p-3">
                                        <div className="tab-pane fade show active" id="allTab">

                                            {this.state.modules.length === 0 ? (
                                                <div className="alert alert-warning" role="alert">
                                                    Não há registros disponíveis.
                                                </div>
                                            ) : (
                                                <div className="table-responsive mb-3">
                                                    <table className="table table-hover table-panel text-nocapitalizewrap align-middle mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th>Módulo</th>
                                                                <th>Menu</th>
                                                                <th>Cadastrado em</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.modules.map((module, index) => (
                                                                <tr key={module.id} className="border">
                                                                    <td className="border">{module.name}</td>
                                                                    <td className="border">{typeToText[module.type] || "Tipo desconhecido"}</td>
                                                                    <td>{moment(module.createdAt).tz("America/Sao_Paulo").format('ddd D MMM, h:mmA')}</td>
                                                                    <td className="text-end">
                                                                        <Link to={`/modules/modules-itens/${module.id}`} className="btn btn-blue btn-sm">Conteúdo</Link>
                                                                        <Link to={`/modules/modules/edit/${module.id}`} className="btn btn-info btn-sm ms-1">Editar</Link>
                                                                        {typeAdmin == 1 && (
                                                                            <button className="btn btn-danger btn-sm ms-1" onClick={() => this.toggleModal(module.id)}>Excluir</button>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>

                                            )}    
                                        </div>
                                    </div>
                                </div>
                            </PanelBody>
                        </Panel>
                        
                    </div>
                </div>

                <Modal isOpen={this.state.showModal} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>Confirmar exclusão</ModalHeader>
                    <ModalBody>
                        Tem certeza de que deseja excluir este módulo e todo o conteúdo relacionado à ele?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggleModal}>Cancelar</Button>{' '}
                        <Button color="danger" onClick={this.handleDeleteItem}>Excluir</Button>
                    </ModalFooter>
                </Modal>

            </div>
        );
    }
}

export default withRouter(ListElementsModulesAdmin);
