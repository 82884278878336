import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import DropdownProfile from './dropdown/profile.jsx';
import { AppSettings } from './../../config/app-settings.js';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

class Header extends React.Component {
	
	static contextType = AppSettings;

	constructor(props) {
		super(props);
		this.toggleAppHeaderMegaMenuMobile = this.toggleAppHeaderMegaMenuMobile.bind(this);
		this.state = { appHeaderMegaMenuMobile: false };
	}

	componentDidMount() {
		
	}

	toggleAppHeaderMegaMenuMobile() {
		this.setState({ appHeaderMegaMenuMobile: !this.state.appHeaderMegaMenuMobile });
	}

	render() {
		
		if (!this.context.appAuthenticated || !cookies.get('cookie_autenticado') || cookies.get('cookie_autenticado') == 'false') {
			return <Redirect to='/login' />;
	 	}
		
		return (
			<AppSettings.Consumer>
				{({toggleAppSidebarMobile, toggleAppSidebarEnd, toggleAppSidebarEndMobile, toggleAppTopMenuMobile, appHeaderLanguageBar, appHeaderMegaMenu, appHeaderInverse, appSidebarTwo, appTopMenu, appSidebarNone}) => (
					<div id="header" className={'app-header ' + (appHeaderInverse ? 'app-header-inverse' : '')}>
						<div className={'navbar-header app-header-left'}>
							{appSidebarTwo && (
								<button type="button" className="navbar-mobile-toggler" onClick={toggleAppSidebarEndMobile}>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
								</button>
							)}
							<Link to="/" className="navbar-brand">
							<div style={{height:60, width:200, marginTop: '10px', backgroundImage: 'url(../assets/img/logo/logo-credihabitar-tipografia-nova.png)', backgroundSize:'contain', backgroundRepeat:'no-repeat'}}></div>
							</Link>
							
							{appHeaderMegaMenu && (
								<button type="button" className="navbar-mobile-toggler" onClick={this.toggleAppHeaderMegaMenuMobile}>
									<span className="fa-stack fa-lg text-inverse">
										<i className="far fa-square fa-stack-2x"></i>
										<i className="fa fa-cog fa-stack-1x"></i>
									</span>
								</button>
							)}
							{appTopMenu && !appSidebarNone && (
							<button type="button" className="navbar-mobile-toggler" onClick={toggleAppTopMenuMobile}>
								<span className="fa-stack fa-lg text-inverse">
									<i className="far fa-square fa-stack-2x"></i>
									<i className="fa fa-cog fa-stack-1x"></i>
								</span>
							</button>
							)}
							{appSidebarNone && appTopMenu && (
								<button type="button" className="navbar-mobile-toggler" onClick={toggleAppTopMenuMobile}>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
								</button>
							)}
							{!appSidebarNone && (
								<button type="button" className="navbar-mobile-toggler" onClick={toggleAppSidebarMobile}>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
								</button>
							)}
						</div>
						
						<div className={'navbar-nav'}>
							
							<DropdownProfile />
							
							{appSidebarTwo && (
								<div className="navbar-divider d-none d-md-block"></div>
							)}
							
							{appSidebarTwo && (
								<div className="navbar-item d-none d-md-block">
									<Link to="/" onClick={toggleAppSidebarEnd} className="navbar-link icon">
										<i className="fa fa-th"></i>
									</Link>
								</div>
							)}
						</div>
					</div>
				)}
			</AppSettings.Consumer>
		)
	}
}

export default Header;
