import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

function Menu() {
    const [activeMenu, setActiveMenu] = useState(null);

    useEffect(() => {
        // Verifica se a página atual é uma página do submenu Administração
        if (window.location.pathname.includes('/clients') || window.location.pathname.includes('/staffs') || window.location.pathname.includes('/modules')) {
            setActiveMenu(2); // Define o menu Administração como ativo
        }
    }, []);

    const toggleMenu = (menuIndex) => {
        if (activeMenu === menuIndex) {
            setActiveMenu(null);
        } else {
            setActiveMenu(menuIndex);
        }
    };

    const userType = cookies.get('cookie_user_type');
    const clientType = cookies.get('cookie_client_type');

    let menuAdmin = '';

    if (clientType == 1) {
        menuAdmin = (
            <li className={activeMenu === 2 ? "active" : ""}>
                <a href="#" data-filter-tags="recursos humanos" onClick={(e) => {
                    e.preventDefault();
                    toggleMenu(2);
                }}>
                    <i className="ion-md-person fs-22px pe-3"></i>
                    <span className="nav-link-text" data-i18n="nav.recursos_humanos">Administração</span>
                    <i className={`fas fa-chevron-${activeMenu === 2 ? 'up' : 'down'}`}></i>
                </a>
                <ul className={activeMenu === 2 ? "submenu-open" : ""}>
                    <li>
                        <a href="clients/clients" data-filter-tags="recursos humanos user list">
                            <span className="nav-link-text" data-i18n="nav.user_list">Licenciados</span>
                        </a>
                    </li>
                    {userType == 1 && (
                        <li>
                            <a href="staffs/staffs" data-filter-tags="recursos humanos user list">
                                <span className="nav-link-text" data-i18n="nav.user_list">Colaboradores</span>
                            </a>
                        </li>
                    )}
                    <li>
                        <a href="/modules/modules" data-filter-tags="recursos humanos edit user">
                            <span className="nav-link-text" data-i18n="nav.edit_user">Módulos</span>
                        </a>
                    </li>
                    <li>
                        <a href="/modules/modules-itens" data-filter-tags="recursos humanos edit user">
                            <span className="nav-link-text" data-i18n="nav.edit_user">Conteúdo</span>
                        </a>
                    </li>
                </ul>
            </li>
        );
    }

    const typeAdmin = cookies.get('cookie_user_type');
    
    return (
        <nav id="js-primary-nav" className="primary-nav position-relative" role="navigation">
            <ul id="js-nav-menu" className="nav-menu">
                
                {/* {(typeAdmin <= 4) && (
                    <li>
                        <a href="/modulos/list-modules" data-filter-tags="modulos">
                            <i className="ion-md-ribbon fs-22px pe-3"></i>
                            <span className="nav-link-text" data-i18n="nav.modulos">Para correspondentes</span>
                        </a>
                    </li>
                )} */}

                <li>
                    <a href="/modulos/list-modules-locked" data-filter-tags="modulos">
                        <i className="ion-md-trophy fs-22px pe-3"></i>
                        <span className="nav-link-text" data-i18n="nav.modulos">Para licenciados</span>
                    </a>
                </li>

                {menuAdmin}
                
            </ul>
            <div className="filter-message js-filter-message bg-success-600"></div>
        </nav>
    );
}

export default Menu;
