import React from 'react';
import { withRouter } from 'react-router-dom';
import authAxios from '../../axiosInstance';
import moment from 'moment-timezone';
import 'moment/locale/pt-br';
import { ReactNotifications } from 'react-notifications-component';
import { Store } from 'react-notifications-component';
import errorHandler from '../../errorHandler.js';
import { AppSettings } from './../../config/app-settings.js';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

moment.locale('pt-br');

class ListElementsModules extends React.Component {
	
	static contextType = AppSettings;
	
	constructor(props) {

		super(props);
		
		this.state = {
            conteudos_video: [],
			conteudos_download: [],
			modalOpen: false,
            modalContent: null,
            modalType: 'video',
			confirmationModalOpen: false,
            itemToMarkComplete: null,
        };

		this.toggleModal = this.toggleModal.bind(this);
		this.toggleConfirmationModal = this.toggleConfirmationModal.bind(this);
        this.handleConfirmCompletion = this.handleConfirmCompletion.bind(this);
	
	}
	
	handleShowVideo = (videoId) => {
        this.setState({
            modalOpen: true,
            modalContent: videoId,
            modalType: 'video'
        });
    }

    handleDownload = (url) => {
        window.open(url, '_blank');
    }

    toggleModal = () => {
        this.setState(prevState => ({ modalOpen: !prevState.modalOpen }));
    }

	toggleConfirmationModal = () => {
        this.setState(prevState => ({ confirmationModalOpen: !prevState.confirmationModalOpen }));
    }

    handleConfirmCompletion = () => {
        
		const formData = new FormData();

		formData.append('itemId', this.state.itemToMarkComplete);
        formData.append('userId', cookies.get('cookie_user_id'));

		const url = '/lessonItensCompleted';
    
        authAxios({
            method: 'post',
            url: url,
            data: formData
        })
        .then(response => {
            this.addNotification('success', 'Sucesso', response.data.message, 'top-right');
            setTimeout(() => {
                window.location.href = window.location.pathname;
            }, 2000);
        })
        .catch(error => {
            this.addNotification('danger', 'Erro', errorHandler(error).message, 'top-right');
        });
		
        this.toggleConfirmationModal();
    }

	renderActionButton = (data) => {
		switch (data.type) {
            case "1":
                return <Button color="primary" className="rounded-0 btn-lg" onClick={() => this.handleDownload(data.fileUrl)}>{data.buttonTitle}</Button>;
            default:
                return <Button color="primary" className="rounded-0 btn-lg">{data.buttonTitle}</Button>;
        }
    }

	componentDidMount() {

		this.getConteudo(this.props.match.params.id);
	
	}
	
	getConteudo(id) {

		const typeToButtonTitle = {
			"1": "Download",
		};

		authAxios.get('/lessonItens/'+id+'/'+cookies.get('cookie_user_id')).then((response) => {

			var json = response.data
			
			const conteudos_download = json.lessonsItens
			.filter(lesson => lesson.type === "1")
			.map(lessonsItens => ({
				id: lessonsItens.id,
				title: lessonsItens.name,
				atualizado: moment(lessonsItens.updatedAt).format("DD/MM/YYYY"),
				buttonTitle: typeToButtonTitle[lessonsItens.type],
				type: lessonsItens.type,
				fileUrl: "../assets/img/gallery/"+lessonsItens.fileUrl
			}));

			const conteudos_video = json.lessonsItens
			.filter(lesson => lesson.type === "2")
			.map(lessonsItens => ({
				id: lessonsItens.id,
				title: lessonsItens.name,
				status: lessonsItens.status,
				atualizado: moment(lessonsItens.updatedAt).format("DD/MM/YYYY"),
				videoId: lessonsItens.videoId,
				imgUrl: "../assets/img/gallery/"+lessonsItens.thumb,
			}));

			this.setState({ conteudos_download });
			this.setState({ conteudos_video });
		
		}).catch((error) => {
			this.addNotification('danger', 'Erro', errorHandler(error).message, 'top-right');
		});

	}

	addNotification(notificationType, notificationTitle, notificationMessage, notificationPosition) {
        Store.addNotification({
            title: notificationTitle,
            message: notificationMessage,
            type: notificationType,
            insert: "top",
            container: notificationPosition,
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: { duration: 2000 },
            dismissable: { click: true },
        });
    }

	render() {
		return (
			
			<div className="pos pos-stock" id="pos-stock">
				
				<h1 className="page-header" style={{marginBottom:'25px'}}>Vídeo aulas</h1>
				<ReactNotifications />
				<div className="row">
					{this.state.conteudos_video.map((conteudo_video) => (
						<div className="col-md-4" style={{marginBottom: '20px'}}>
							<div className="result-list">
								<div className="new-result-item">
									<div className="result-info">
										<div onClick={() => this.handleShowVideo(conteudo_video.videoId)} style={{height:400, width:'100%', cursor:'pointer', backgroundImage: `url(${conteudo_video.imgUrl})`, backgroundSize:'cover', backgroundRepeat:'no-repeat'}}></div>
										<h4 className="title" style={{paddingTop:'20px'}}>{conteudo_video.title}</h4>
										<p className="location">Atualizado em {conteudo_video.atualizado}</p>
										{/* <div className="btn-row">
											{conteudo_video.status === 0 && cookies.get('cookie_client_type') == 3 && (
												<Button color="primary" className="rounded-0 btn-lg" onClick={() => {
													this.setState({ itemToMarkComplete: conteudo_video.id });
													this.toggleConfirmationModal();
												}}>Concluir</Button>
											)}
										</div> */}
									</div>
								</div>
							</div>	
						</div>	
					))}
				</div>
				
				<h1 className="page-header" style={{marginTop:'25px', marginBottom:'25px'}}>Materiais de apoio</h1>

				<div className="row">
					{this.state.conteudos_download.map((conteudo_download) => (
						<div className="col-md-12" style={{marginBottom: '20px'}}>
							<div className="result-list">
								<div className="new-result-item">
									<div className="result-info">
										<div className="row">
											<div className="col-md-12">
												<h4 className="title">{conteudo_download.title}</h4>
												<p className="location">Atualizado em {conteudo_download.atualizado}</p>
												<div className="btn-row">
													{this.renderActionButton(conteudo_download)}
												</div>
											</div>
										</div>		
									</div>
								</div>
							</div>	
						</div>	
					))}
				</div>

				<Modal isOpen={this.state.modalOpen} toggle={this.toggleModal}>
                    <ModalBody>
                        {this.state.modalType === 'video' ? (
                            <iframe title="video" width="100%" height="315" src={this.state.modalContent} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        ) : (
                            <p>{this.state.modalContent}</p>
                        )}
                    </ModalBody>
                    <Button color="primary" onClick={this.toggleModal}>Fechar</Button>
                </Modal>

				<Modal isOpen={this.state.confirmationModalOpen} toggle={this.toggleConfirmationModal}>
					<ModalHeader toggle={this.toggleModal}>Confirmar conclusão</ModalHeader>
					<ModalBody>
						<p>Tem certeza que deseja marcar este conteúdo como concluído?</p>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={this.handleConfirmCompletion}>Concluir</Button>
						<Button color="secondary" onClick={this.toggleConfirmationModal}>Cancelar</Button>
					</ModalFooter>
				</Modal>

			</div>

		)
	}
}

export default withRouter(ListElementsModules);