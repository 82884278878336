import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Panel, PanelBody } from './../../components/panel/panel.jsx';
import authAxios from '../../axiosInstance';
import { ReactNotifications } from 'react-notifications-component';
import { Store } from 'react-notifications-component';
import moment from 'moment-timezone';
import 'moment/locale/pt-br';
import errorHandler from '../../errorHandler.js';
import { AppSettings } from './../../config/app-settings.js';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

moment.locale('pt-br');

function capitalize(str) {
	return str.replace(/\b\w/g, l => l.toUpperCase());
}

class ListElementsClients extends React.Component {
	
	static contextType = AppSettings;
	
	constructor(props) {

		super(props);
		
		this.state = {
            title: "Licenciados",
			subtitle: "Lista",
            clients: [],
			showModal: false,
            itemToDeleteId: null,
            showDisableModal: false,
            itemToDisableId: null,
            showEnableModal: false,
            itemToEnableId: null,
			showPromoteModal: false,
    		itemToPromoteId: null,
			filters: {
                empresa: '',
                organizacao: '',
                licenciado: '',
				alias: '',
				permissao: '',
				status: '1',
            }
        };

		this.handleFilterChange = this.handleFilterChange.bind(this);
		this.getClients = this.getClients.bind(this);;
		this.handleOnChange = this.handleOnChange.bind(this);
	
	}
	
	handleOnChange() {
	
	}

	componentDidMount() {
		this.getClients();
	}

	toggleModal = (itemId) => {
        this.setState(prevState => ({
            showModal: !prevState.showModal,
            itemToDeleteId: itemId
        }));
    };
    
	toggleDisableModal = (itemId) => {
        this.setState(prevState => ({
            showDisableModal: !prevState.showDisableModal,
            itemToDisableId: itemId
        }));
    };

    toggleEnableModal = (itemId) => {
        this.setState(prevState => ({
            showEnableModal: !prevState.showEnableModal,
            itemToEnableId: itemId
        }));
    };

	togglePromoteModal = (itemId) => {
		this.setState(prevState => ({
			showPromoteModal: !prevState.showPromoteModal,
			itemToPromoteId: itemId
		}));
	};

	handlePromoteItem = () => {
		const itemId = this.state.itemToPromoteId;
	
		authAxios.put(`/userPromote/${itemId}`).then(response => {
            
            this.addNotification('success', 'Successo', response.data.message, 'top-right');
            setTimeout(() => {
                window.location.href = '/clients/clients';
            }, 2000);
            
        }).catch(error => {
            this.addNotification('danger', 'Erro', errorHandler(error).message, 'top-right');
        });
	
		this.togglePromoteModal(null);
	};

    handleDeleteItem = () => {
        const itemId = this.state.itemToDeleteId;
        
        authAxios.delete(`/individual/${itemId}`).then(response => {
            
            this.addNotification('success', 'Successo', response.data.message, 'top-right');
            setTimeout(() => {
                window.location.href = '/clients/clients';
            }, 2000);
            
        }).catch(error => {
            this.addNotification('danger', 'Erro', errorHandler(error).message, 'top-right');
        });

        
        this.toggleModal(null);
    };

	handleDisableItem = () => {
        const itemId = this.state.itemToDisableId;
    
        authAxios.put(`/userDisable/${itemId}`).then(response => {
            
            this.addNotification('success', 'Successo', response.data.message, 'top-right');
            setTimeout(() => {
                window.location.href = '/clients/clients';
            }, 2000);
            
        }).catch(error => {
            this.addNotification('danger', 'Erro', errorHandler(error).message, 'top-right');
        });
    
        this.toggleDisableModal(null);
    };

    handleEnableItem = () => {
        const itemId = this.state.itemToEnableId;
    
        authAxios.put(`/userEnable/${itemId}`).then(response => {
            
            this.addNotification('success', 'Successo', response.data.message, 'top-right');
            setTimeout(() => {
                window.location.href = '/clients/clients';
            }, 2000);
            
        }).catch(error => {
            this.addNotification('danger', 'Erro', errorHandler(error).message, 'top-right');
        });
    
        this.toggleEnableModal(null);
    };

	handleFilterChange(e) {
        this.setState({
            filters: { ...this.state.filters, [e.target.name]: e.target.value }
        }, this.getClients);
    }
	
	getClients() {
		const { empresa, organizacao, licenciado, alias, permissao, status } = this.state.filters;
        const queryParams = new URLSearchParams({ empresa, organizacao, licenciado, alias, permissao, status }).toString();
		const url = `/client?${queryParams}`;

		authAxios.get(url).then((response) => {
	
			var json = response.data;
			const desiredTypes = ["3", "4", "5"];
	
			const clients = json.clients
				.filter(client => desiredTypes.includes(client.type))
				.flatMap(client => client.individuals.map(individual => ({
					clientId: client.id,
					clientName: client.name,
					type: client.type,
					createdAt: client.createdAt,
					individualId: individual.id,
					individualName: individual.name,
					individualType: individual.type,
					individualUser: individual.user,
					individualItens: individual.itens,
					individualitensCompleted: individual.itensCompleted,
				})));
	
			this.setState({ clients });
			
		}).catch((error) => {
			this.addNotification('danger', 'Erro', errorHandler(error).message, 'top-right');
		});
	}

	addNotification(notificationType, notificationTitle, notificationMessage, notificationPosition) {
        Store.addNotification({
            title: notificationTitle,
            message: notificationMessage,
            type: notificationType,
            insert: "top",
            container: notificationPosition,
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: { duration: 2000 },
            dismissable: { click: true },
        });
    }

	render() {

		const typeToText = {
			"3": "Correspondente",
			"4": "Promovido à licenciado",
			"5": "Licenciado nato"
		};

		const typeIndividual = {
			"1": "Coletiva",
			"2": "Individual"
		};

		const typeAdmin = cookies.get('cookie_user_type');

		return (
			
			<div>

				<div className="row">
					<div className="col-md-9 mb-3 mb-md-0">
						<h1 className="page-header" style={{marginTop:'40px'}}>Licenciados</h1>
					</div>
					<div className="col-xl-3 text-end">
						<Link to="/clients/clients/add" className="btn btn-lg btn-primary">Cadastrar</Link>
					</div>
				</div>

				<div className="row mb-3">
					<div className="col-xl-12">					
						<Panel>
							<ReactNotifications />
							<PanelBody>

								<div className="filter-panel mb-3">
									<h1 className="page-header" style={{ marginTop: '20px', marginLeft:'15px', fontSize:'18px' }}>Filtros</h1>
									<div className="row mb-3" style={{marginLeft:'5px'}}>
										<div className="col-md-2">
											<input
												type="text"
												name="empresa"
												placeholder="Empresa"
												value={this.state.filters.empresa}
												onChange={this.handleFilterChange}
												style={{ padding: '8px' }}
												className='form-control' 
											/>
										</div>
										<div className="col-md-2">
											<input
												type="text"
												name="licenciado"
												placeholder="Licenciado"
												value={this.state.filters.licenciado}
												onChange={this.handleFilterChange}
												style={{ padding: '8px' }}
												className='form-control' 
											/>
										</div>
										<div className="col-md-2">
											<input
												type="text"
												name="alias"
												placeholder="Alias"
												value={this.state.filters.alias}
												onChange={this.handleFilterChange}
												style={{ padding: '8px' }}
												className='form-control' 
											/>
										</div>
										<div className="col-md-2">
											<select
												name="permissao"
												value={this.state.filters.permissao}
												onChange={this.handleFilterChange}
												style={{ padding: '8px' }}
												className='form-select'
											>
												<option value="">Todas as permissões</option>
												<option value="3">Correspondente</option>
												<option value="4">Promovido à licenciado</option>
												<option value="5">Licenciado nato</option>
											</select>
										</div>
										<div className="col-md-2">
											<select
												name="status"
												value={this.state.filters.status}
												onChange={this.handleFilterChange}
												style={{ padding: '8px' }}
												className='form-select'
											>
												<option value="">Todos os status</option>
												<option value="0">Inativos</option>
												<option value="1">Ativos</option>
											</select>
										</div>
									</div>
								</div>

								<div className="card border-0">
									<div className="tab-content p-3">
										<div className="tab-pane fade show active" id="allTab">

											{this.state.clients.length === 0 ? (
                                                <div className="alert alert-warning" role="alert">
                                                    Não há registros disponíveis.
                                                </div>
                                            ) : (
												<div className="table-responsive mb-3">
													<table className="table table-hover table-panel text-nocapitalizewrap align-middle mb-0">
														<thead>
															<tr>
																<th>Empresa</th>
																<th>Licenciado</th>
																<th>Alias</th>
																<th>Permissão</th>
																<th>Cadastrado em</th>
																<th></th>
															</tr>
														</thead>
														<tbody>

															{this.state.clients.map((client, index) => (
																<tr key={`${client.clientId}-${client.individualId}`} className="border">
																	<td className="border">
																		{client.clientName}
																	</td>
																	<td className="border">
																		{client.individualName}
																	</td>
																	<td className="border">
																		{client.individualUser.alias}
																	</td>
																	<td className="border">
																		{typeToText[client.individualUser.type] || "Tipo desconhecido"}
																	</td>
																	<td>
																		{capitalize(moment(client.createdAt).tz("America/Sao_Paulo").format('ddd D MMM, h:mmA'))}
																	</td>
																	<td className="text-end">
																		{typeAdmin == 1 && client.individualUser.type === "3" && (client.individualItens === client.individualitensCompleted)  && (
																			<button className="btn btn-green btn-sm ms-1" onClick={() => this.togglePromoteModal(client.individualUser.id)}>Promover</button>
																		)}
																		
																		<Link to={`/clients/clients/edit/${client.individualId}`} className="btn btn-info btn-sm ms-1">Editar</Link>

																		{client.individualUser.status === '1' && (
																			<button className="btn btn-warning btn-sm ms-1" onClick={() => this.toggleDisableModal(client.individualUser.id)}>Desativar</button>
																		)}

																		{client.individualUser.status === '0' && (
																			<button className="btn btn-warning btn-sm ms-1" onClick={() => this.toggleEnableModal(client.individualUser.id)}>Ativar</button>
																		)}

																		{typeAdmin == 1 && (
																			<button className="btn btn-danger btn-sm ms-1" onClick={() => this.toggleModal(client.individualId)}>Excluir</button>
																		)}
																	</td>
																</tr>
															))}


														</tbody>
													</table>
												</div>
											)}	
										</div>
									</div>
								</div>

							</PanelBody>
						</Panel>

					</div>
				</div>	

				<Modal isOpen={this.state.showModal} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>Confirmar exclusão</ModalHeader>
                    <ModalBody>
                        Tem certeza de que deseja excluir este licenciado?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggleModal}>Cancelar</Button>{' '}
                        <Button color="danger" onClick={this.handleDeleteItem}>Excluir</Button>
                    </ModalFooter>
                </Modal>

				<Modal isOpen={this.state.showDisableModal} toggle={this.toggleDisableModal}>
                    <ModalHeader toggle={this.toggleDisableModal}>Confirmar desativação</ModalHeader>
                    <ModalBody>
                        Tem certeza de que deseja desativar este licenciado?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggleDisableModal}>Cancelar</Button>{' '}
                        <Button color="warning" onClick={this.handleDisableItem}>Desativar</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.showEnableModal} toggle={this.toggleEnableModal}>
                    <ModalHeader toggle={this.toggleEnableModal}>Confirmar habilitação</ModalHeader>
                    <ModalBody>
                        Tem certeza de que deseja habilitar este licenciado?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggleEnableModal}>Cancelar</Button>{' '}
                        <Button color="warning" onClick={this.handleEnableItem}>Ativar</Button>
                    </ModalFooter>
                </Modal>

				<Modal isOpen={this.state.showPromoteModal} toggle={this.togglePromoteModal}>
					<ModalHeader toggle={this.togglePromoteModal}>Confirmar promoção</ModalHeader>
					<ModalBody>
						Tem certeza de que deseja promover este licenciado?
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" onClick={this.togglePromoteModal}>Cancelar</Button>{' '}
						<Button color="green" onClick={this.handlePromoteItem}>Promover</Button> {/* Botão de promoção */}
					</ModalFooter>
				</Modal>

			</div>

		)
	}
}

export default withRouter(ListElementsClients);