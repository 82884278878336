import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Panel, PanelBody } from './../../components/panel/panel.jsx';
import authAxios from '../../axiosInstance';
import { ReactNotifications } from 'react-notifications-component';
import { Store } from 'react-notifications-component';
import errorHandler from '../../errorHandler.js';
import { AppSettings } from './../../config/app-settings';

class FormElementsModulesItensAdmin extends React.Component {
    static contextType = AppSettings;
    
    constructor(props) {
        super(props);
        this.state = {
            title: "Centros de Custo",
            subtitle: "",
            button: "Cadastrar",
            formDataId: "",
            formDataName: "",
            formDataContent: null,
            formDataLesson: '',
            formDataType: "",
            formDataFileRequired: "*",
            file: null,
            fileType: "",
            lessons: []
        };
        this.handleOnChange = this.handleOnChange.bind(this);
        this.addNotification = this.addNotification.bind(this);
        this.sendForm = this.sendForm.bind(this);
        this.getModuleItem = this.getModuleItem.bind(this);
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        this.getModules();
        if (id) {
            this.setState({ formDataId: id, formDataFileRequired: '', button: 'Salvar', subtitle: 'Edição' });
            this.getModuleItem(id);
        }
    }

    getModules() {
        authAxios.get('/lesson').then(response => {
            this.setState({ lessons: response.data.lessons });
        }).catch(error => {
            this.addNotification('danger', 'Erro', errorHandler(error).message, 'top-right');
        });
    }

    getModuleItem(id) {
        authAxios.get(`/lessonItem/${id}`).then(response => {
            const { name, type, lessonIdFK, videoId } = response.data.lessonItem;
            this.setState({
                formDataName: name,
                formDataType: type.toString(),
                formDataLesson: lessonIdFK.toString(),
                formDataContent: type === "2" ? videoId : null
            });
        }).catch(error => {
            this.addNotification('danger', 'Erro', errorHandler(error).message, 'top-right');
        });
    }

    handleOnChange(event) {
        const { name, value, files } = event.target;
        if (name === "file") {
            this.setState({ file: files ? files[0] : null });
        } else {
            this.setState({ [name]: value });
        }
    }

    addNotification(notificationType, notificationTitle, notificationMessage, notificationPosition) {
        Store.addNotification({
            title: notificationTitle,
            message: notificationMessage,
            type: notificationType,
            insert: "top",
            container: notificationPosition,
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: { duration: 2000 },
            dismissable: { click: true },
        });
    }

    sendForm(e) {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', this.state.formDataName);
        formData.append('type', this.state.formDataType);
        formData.append('lessonIdFK', this.state.formDataLesson);
        if (this.state.file) {
            formData.append('file', this.state.file);
        }
        formData.append('content', this.state.formDataType === "2" ? this.state.formDataContent : null);

        const config = { headers: { 'content-type': 'multipart/form-data' } };

        let url = ''

        if(this.state.formDataType === "1"){
            url = this.state.formDataId ? `/lessonItens/${this.state.formDataId}` : '/lessonItens';
        } else {
            url = this.state.formDataId ? `/lessonItensVideo/${this.state.formDataId}` : '/lessonItensVideo';
        }
        
        authAxios({
            method: this.state.formDataId ? 'put' : 'post',
            url: url,
            data: formData,
            ...config
        })
        .then(response => {
            this.addNotification('success', 'Successo', response.data.message, 'top-right');
            setTimeout(() => {
                window.location.href = '/modules/modules-itens';
            }, 2000);
        })
        .catch(error => {
            this.addNotification('danger', 'Erro', errorHandler(error).message, 'top-right');
        });
    }

    render() {
        const fileAccept = this.state.formDataType === "1" ? ".zip,.rar,.doc,.pdf" : this.state.formDataType === "2" ? ".jpg,.png" : "";
        const fileAcceptLabel = this.state.formDataType === "1" ? "Arquivo (.zip,.rar,.doc,.pdf) - Máx. 5MB " + this.state.formDataFileRequired : this.state.formDataType === "2" ? "Thumb (.jpg,.png) - Máx. 1MB " + this.state.formDataFileRequired: "Arquivo " +  this.state.formDataFileRequired;

        return (
            <div>
                <div className="row">
                    <div className="col-md-9 mb-3 mb-md-0">
                        <h1 className="page-header" style={{ marginTop: '40px' }}>Conteúdo</h1>
                    </div>
                    <div className="col-xl-3 text-end">
                        <Link to="/modules/modules-itens" className="btn btn-lg btn-gray">Voltar</Link>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-xl-12">
                        <Panel>
                            <ReactNotifications />
                            <PanelBody>
                                <div className="row">
                                    <div className="col-md-12" style={{fontSize: '15px', marginBottom: '25px', marginTop: '25px'}}>

                                        - Caso esteja fazendo um cadastro, <b>todos</b> os campos são de preenchimento obrigatório;<br></br>
                                        - Em caso de edição, o campo <b>Arquivo/Thumb</b> apenas deve ser preenchido caso deseje alterar o arquivo/thumb atuais.

                                    </div>
                                </div>    
                                <form onSubmit={this.sendForm}>
                                    <div className="row">
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label" style={{fontWeight: 500}}>Módulo *</label>
                                            <select
                                                className="form-select"
                                                name="formDataLesson"
                                                value={this.state.formDataLesson}
                                                onChange={this.handleOnChange}
                                                required
                                            >
                                                <option value="">Selecione um módulo</option>
                                                {this.state.lessons.map((lesson) => (
                                                    <option key={lesson.id} value={lesson.id}>
                                                        {lesson.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label" style={{fontWeight: 500}}>Nome *</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="formDataName"
                                                value={this.state.formDataName}
                                                onChange={this.handleOnChange}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label" style={{fontWeight: 500}}>Tipo *</label>
                                            <select
                                                className="form-select"
                                                name="formDataType"
                                                value={this.state.formDataType}
                                                onChange={this.handleOnChange}
                                                required
                                            >
                                                <option value="">Selecione</option>
                                                <option value="1">Arquivo para download</option>
                                                <option value="2">Vídeo aula</option                                                >
                                            </select>
                                        </div>
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label" style={{fontWeight: 500}}>{fileAcceptLabel}</label>
                                            <input
                                                type="file"
                                                className="form-control"
                                                name="file"
                                                onChange={this.handleOnChange}
                                                accept={fileAccept}
                                                required={!this.state.formDataId}
                                            />
                                        </div>
                                        {this.state.formDataType === "2" && (
                                            <div className="mb-3 col-md-4">
                                                <label className="form-label" style={{fontWeight: 500}}>Vídeo *</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="formDataContent"
                                                    value={this.state.formDataContent}
                                                    onChange={this.handleOnChange}
                                                    required
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <button type="submit" className="btn btn-primary">{this.state.button}</button>
                                </form>
                            </PanelBody>
                        </Panel>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(FormElementsModulesItensAdmin);

