import React from 'react';
import { Link } from 'react-router-dom';
import authAxios from '../../axiosInstance';
import { AppSettings } from './../../config/app-settings.js';
import { ReactNotifications } from 'react-notifications-component';
import { Store } from 'react-notifications-component';
import errorHandler from '../../errorHandler.js';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

class SidebarProfile extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			profileActive: 0,
			showUploadModal: false,
            file: null
		};
		this.handleProfileExpand = this.handleProfileExpand.bind(this);
		this.handleOnChange = this.handleOnChange.bind(this);
	}

	handleProfileExpand(e) {
		e.preventDefault();
		this.setState(state => ({
			profileActive: !this.state.profileActive,
		}));
	}

	toggleUploadModal = () => {
        this.setState(prevState => ({
            showUploadModal: !prevState.showUploadModal
        }));
    }

    handleOnChange(event) {
        const { name, value, files } = event.target;
        if (name === "file") {
            this.setState({ [name]: files ? files[0] : null });
        }
    }

    handleUpload = (e) => {
        
		e.preventDefault();

		const formData = new FormData();
        formData.append('file', this.state.file);
        
        const config = { headers: { 'content-type': 'multipart/form-data' } };
        const url = `/uploadBannerMenu/2`;

        authAxios({
            method: 'put',
            url: url,
            data: formData,
            ...config
        })
        .then(response => {
            this.addNotification('success', 'Successo', response.data.message, 'top-right');
            setTimeout(() => {
                window.location.href = '/modulos/list-modules';
            }, 2000);
        })
        .catch(error => {
            this.addNotification('danger', 'Erro', errorHandler(error).message, 'top-right');
        });
        
        this.setState({
            showUploadModal: false,
            file: null
        });
    }

	addNotification(notificationType, notificationTitle, notificationMessage, notificationPosition, notificationContent) {
		
		Store.addNotification({
		  title: notificationTitle,
		  message: notificationMessage,
		  type: notificationType,
		  insert: "top",
		  container: notificationPosition,
		  animationIn: ["animated", "fadeIn"],
		  animationOut: ["animated", "fadeOut"],
		  dismiss: { duration: 2000 },
		  dismissable: { click: true },
		  content: notificationContent
		});
		
	}
  
	render() {

		const clientType = cookies.get('cookie_client_type');

		let uploadBanner = '';

		if(clientType == 1){
			
			uploadBanner = <div className="upload-icon" onClick={this.toggleUploadModal} style={{position:'absolute', right:'0px', cursor:'pointer', zIndex:'100'}}>
			<i className="fas fa-lg fa-fw me-10px fa-image fs-16px" style={{marginTop:'15px'}}></i>
			</div>
		}

		return (
			<AppSettings.Consumer>
				{({appSidebarMinify}) => (
					<div>
						
						{uploadBanner}
						<ReactNotifications />	

						<div className={"menu-profile " + (this.state.profileActive ? "expand " : "")}>
							<Link to="#" className="menu-profile-link">
								<div className="menu-profile-cover with-shadow">
									<div style={{height:150, minWidth:'100%', backgroundImage: 'url(../assets/img/pos/banner_menu.jpg)', backgroundSize:'cover', backgroundRepeat:'no-repeat'}}></div>
								</div>
								<div className="menu-profile-image">
									
								</div>
								<div className="menu-profile-info">
									<div className="d-flex align-items-center">
										<div className="flex-grow-1" style={{fontSize: '16px'}}>
											{cookies.get('cookie_user_alias')}
										</div>
									</div>
								</div>
							</Link>
						</div>
						<div className={"collapse " + (this.state.profileActive && !appSidebarMinify ? "show " : "")}>
							<div className="menu-item pt-5px">
								<Link to="/" className="menu-link">
									<div className="menu-icon"><i className="fa fa-cog"></i></div>
									<div className="menu-text">Settings</div>
								</Link>
							</div>
							<div className="menu-item">
								<Link to="/" className="menu-link">
									<div className="menu-icon"><i className="fa fa-pencil-alt"></i></div>
									<div className="menu-text"> Send Feedback</div>
								</Link>
							</div>
							<div className="menu-item pb-5px">
								<Link to="/" className="menu-link">
									<div className="menu-icon"><i className="fa fa-question-circle"></i></div>
									<div className="menu-text"> Helps</div>
								</Link>
							</div>
							<div className="menu-divider m-0"></div>
						</div>

						<Modal isOpen={this.state.showUploadModal} toggle={this.toggleUploadModal}>
							<ModalHeader toggle={this.toggleUploadModal}>Selecionar arquivo</ModalHeader>
							<ModalBody>
								<form>
									<div className="row">
										<div className="mb-3 col-md-12">
											<label className="form-label" style={{fontWeight: 500}}>Imagem (.jpg) - Máx. 1MB</label>
											<input
												type="file"
												className="form-control"
												name="file"
												onChange={this.handleOnChange}
												accept=".jpg"
												required='true'
											/>
										</div>
									</div>
								</form>
							</ModalBody>
							<ModalFooter>
								<Button color="primary" onClick={this.handleUpload}>Enviar</Button>
								<Button color="secondary" onClick={this.toggleUploadModal}>Cancelar</Button>
							</ModalFooter>
						</Modal>
						
					</div>
				)}
			</AppSettings.Consumer>
		)
	}
}

export default SidebarProfile;