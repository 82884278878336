import { Redirect } from "react-router-dom";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const errorHandler = (error) => {

    const { request, response } = error;
    
    if (response) {
      
      const { message } = response.data;
      const status = response.status;
       
      return {
          message,
          status
      };
    
    } else if (request) {
      
      return {
        message: "server time out",
        status: 503
      };

    } else {

      return { message: "Alguma coisa deu errado!" };
    
    }
};

export default errorHandler;